import React from "react";
import "./ThreeStateCheckbox.scss"

export default function ThreeStateCheckbox(props) {
  const { name, label, checked, indeterminate, onChange } = props;
  const inputRef = React.useRef(null);

  const updateInput = (ref, checked, indeterminate) => {
    const input = ref.current;
    if (input) {
      input.checked = checked;
      input.indeterminate = indeterminate;
    }
  };

  React.useEffect(() => {
    updateInput(inputRef, checked, indeterminate);
  }, [checked, indeterminate]);

  return (
    <div className="ThreeStateCheckbox">
      <input
        ref={inputRef}
        type="checkbox"
        name={name}
        onClick={onChange}
        id={name}
      />
      <label htmlFor={name} className="checkbox-label">
        {label}
      </label>
    </div>
  );
}
