import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";
import {JOB_TYPE_ALL_FIELDS_FRAGMENT} from "../view/useQueryJobById";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobUpdateDescription";

export const QUERY = gql`
    ${JOB_TYPE_ALL_FIELDS_FRAGMENT}
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
        $id: ID!,
        $title: String,
        $description: Html,
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          id: $id,
          title: $title,
          description: $description,
        ) {
          ...JobTypeAllFieldsFragment
        }
      }
    }
  `;

export default function useMutationJobUpdateDescription() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
