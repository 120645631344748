import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationCandidates from "../useTranslationCandidates";
import useQueryApplicationSearch from "../search/useQueryApplicationSearch";
import Loader from "../../common/loading-widgets/Loader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ApplicationsList from "./ApplicationsList";

export default function CandidatesList(props) {
  const { jobId, status, onFilterChange } = props;

  const CANDIDATES_SORT_TYPE = "DATE_DESC";
  const CANDIDATES_LIST_LENGTH = 50;
  const { data, loading, errors } = useQueryApplicationSearch(
    jobId,
    CANDIDATES_LIST_LENGTH,
    { status },
    CANDIDATES_SORT_TYPE
  );

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ApplicationsList");

  return (
    <div className={"ApplicationsList form-with-rows"}>
      <Loader>
        <WaitForData
          loading={loading}
          errors={errors}
          onLoaded={() => (
            <>
              {data["nodes"].length === 0 && (
                <div>
                  <Row>
                    <Col>{t("candidates:candidates_no_candidates_msg")}</Col>
                  </Row>
                </div>
              )}
              {data["nodes"].length > 0 && (
                <ApplicationsList
                  applications={data["nodes"]}
                  status={status}
                  onFilterChange={onFilterChange}
                />
              )}
            </>
          )}
        />
      </Loader>
    </div>
  );
}
