import React from "react";
import "./NotFoundPage.scss";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import useTranslationApp from "./useTranslationApp";

export default function NotFoundPage() {
  // RENDER
  const {t, loading: tLoading} = useTranslationApp(useShowLoader);

  useShowLoader(tLoading, "NotFoundPage");

  return (
    <div className="NotFoundPage">
      {t('app:notfound_content')}
    </div>
  );
}
