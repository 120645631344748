import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobUpdateDescription from "./useMutationJobUpdateDescription";
import { STEP_ENUM } from "./JobSaveForm";
import {
  formatWysiwygInput,
  formatWysiwygOutput,
  getWysiwygContentLength,
} from "../../common/widgets/WysiwygEditor";
import ValidatedInputWysiwyg from "../../common/widgets/ValidatedInputWysiwyg";
import { MAX_LENGTH_JOB_DESCRIPTION } from "../../../constants/ui";
import WysiwygCounterDisplay from "../../common/widgets/WysiwygCounterDisplay";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm";
import "./JobSaveDescriptionForm.scss";
import useContentModal from "../../common/modals/useContentModal";
import HtmlUtils from "../../../utils/HtmlUtils";
import DotsIcon from "../../../img/horizontal-dots-small.gif";
import Image from "react-bootstrap/Image";

export default function useJobSaveDescriptionForm(job, isJobSaved) {
  const { t, loading: tLoading } = useTranslationJobs();
  const {
    mutate,
    loading: mutationLoading,
    errors,
  } = useMutationJobUpdateDescription();

  useShowLoader(tLoading);

  const submit = () => {
    return mutate({
      variables: {
        id: job._id,
        description: formatWysiwygOutput(description),
        lastSavedStep: isJobSaved ? undefined : STEP_ENUM.DESCRIPTION,
      },
    });
  };

  const {
    control,
    formState: { isValid },
    watch,
  } = useValidatedInputForm();

  // Get number of characters typed in the wysiwyg (not counting hidden html tags)
  const description = watch("description");
  const textLength = getWysiwygContentLength(description);

  const canSubmit = () => isValid;

  // Modal while waiting
  const {
    ContentModal: WaitingModal,
    show: showWaitingModal,
    hide: hideWaitingModal,
  } = useContentModal("WaitingModal");

  // Display a modal when waiting for the mutation to finish
  React.useEffect(() => {
    if (mutationLoading) {
      showWaitingModal(
        <div className={"JobSaveDescriptionWaitingModal"}>
          <div>
            {HtmlUtils.htmlToComponents(t("jobs:employers_job_save_description_wait_msg"))}
          </div>
          <div className={"loading-icon"}>
            <Image src={DotsIcon}/>
          </div>
        </div>
      );
    } else {
      hideWaitingModal();
    }
  }, [mutationLoading, showWaitingModal, hideWaitingModal, t]);

  const form = (
    <>
      {WaitingModal}
      <div className="JobSaveDescriptionForm">
        <Row>
          <Col className="job-description-invite">
            {t("jobs:employers_job_save_description_invite_msg")}
          </Col>
        </Row>
        <Row>
          <Col>
            <ValidatedInputWysiwyg
              name={"description"}
              defaultValue={formatWysiwygInput(job.description)}
              control={control}
              maxLength={MAX_LENGTH_JOB_DESCRIPTION}
              required
            />
            <WysiwygCounterDisplay
              maxLength={MAX_LENGTH_JOB_DESCRIPTION}
              length={textLength}
            />
          </Col>
        </Row>
      </div>
    </>
  );

  return {
    form,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: mutationLoading,
    errors,
  };
}
