import React from "react";
import Col from "react-bootstrap/Col";
import "./JobDashboardApplicationsCount.scss";
import Row from "react-bootstrap/Row";

function JobDashboardApplicationsCount(props) {
  const { label, count } = props;

  return (
    <Row className = "JobDashboardApplicationsCount">
      <Col className="count-label-col">{label}</Col>
      <Col className="count-col">{count}</Col>
    </Row>
  );
}

export default JobDashboardApplicationsCount;
