import React from "react";
import Page from "../../common/page/Page";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import ApplicationListBlock from "./ApplicationListBlock";
import {useLocation} from "react-router-dom";
import queryString from "query-string";


export default function ApplicationListPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "ApplicationListPage");
  const location = useLocation()

  const filter = queryString.parse(location.search);

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="ApplicationListPage">
          <Page.Title
            text={t("admin:admin_application_list_title")}
            loading={tLoading}
          />
          <Page.Content>
            <ApplicationListBlock filter={filter}/>
          </Page.Content>
        </Page>
      )}
    />
  );
}
