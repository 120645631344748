import {gql} from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "recruiterAdmin";
const QUERY_NAME = "recruiterAdminSearch";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($pageSize: Int, $filter: RecruiterAdminFilterInput, $sort: RecruiterSortEnum) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(pageSize: $pageSize, filter: $filter, sort: $sort) {
        nodes {
          _id
          firstName
          lastName
          phone
          email
          jobTitle
          city
          country
          aboutMe
          isActiveAccount
          createdAt
        }
      }
    }
  }
`;

export default function useQueryRecruiterAdminSearch(pageSize, filter, sort) {
  const variables = { pageSize, filter, sort };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
