import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationCandidates from "../useTranslationCandidates";
import ButtonGroup from "../../common/components/ButtonGroup";
import ApplicationStatusEnum from "../ApplicationStatusEnum";

function CandidatesFilter(props) {
  const { filter, counts, onFilterClick } = props;

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidatesFilter");

  const getCountForStatus = (status) => {
    const statusItem = counts.find((item) => item.status === status);
    return statusItem ? statusItem["count"] : 0;
  };

  const filterLabels = [
    {
      id: ApplicationStatusEnum.STACK,
      label: t("candidates:candidates_status_stack_label", {
        count: getCountForStatus(ApplicationStatusEnum.STACK),
      }),
    },
    {
      id: ApplicationStatusEnum.REJECTED,
      label: t("candidates:candidates_status_rejected_label", {
        count: getCountForStatus(ApplicationStatusEnum.REJECTED),
      }),
    },
    {
      id: ApplicationStatusEnum.SHORTLIST,
      label: t("candidates:candidates_status_shortlist_label", {
        count: getCountForStatus(ApplicationStatusEnum.SHORTLIST),
      }),
    },
    {
      id: ApplicationStatusEnum.INTERVIEW,
      label: t("candidates:candidates_status_interview_label", {
        count: getCountForStatus(ApplicationStatusEnum.INTERVIEW),
      }),
    },
  ];

  return (
    <>
      <ButtonGroup
        buttons={filterLabels}
        selectedId={filter}
        onClick={onFilterClick}
      />
    </>
  );
}

export default CandidatesFilter;
