import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobUpdate from "./useMutationJobUpdate";
import EditableTags from "./EditableTags";
import TypeUtils from "../../../utils/TypeUtils";
import { STEP_ENUM } from "./JobSaveForm";
import "./JobSaveSkillsForm.scss";

export default function useJobSaveSkillsForm(job, isStepSaved) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { mutate, loading: mutationLoading, errors } = useMutationJobUpdate();
  useShowLoader(tLoading);

  // If step has already been saved (user previously modified the suggested skills), display user-defined skills,
  // otherwise display suggestions from analysis.
  const initialTechSkills = React.useMemo(
    () =>
      isStepSaved
        ? TypeUtils.ensureArray(job.technicalSkills)
        : TypeUtils.ensureArray(job.nlpRequiredTechnicalSkills),
    [isStepSaved, job.technicalSkills, job.nlpRequiredTechnicalSkills]
  );

  const initialSoftSkills = React.useMemo(
    () =>
      isStepSaved
        ? TypeUtils.ensureArray(job.softSkills)
        : TypeUtils.ensureArray(job.nlpRequiredSoftSkills),
    [isStepSaved, job.softSkills, job.nlpRequiredSoftSkills]
  );

  const [techSkills, setTechSkills] = React.useState(initialTechSkills);
  const [softSkills, setSoftSkills] = React.useState(initialSoftSkills);

  // The prompts are not the same whether user has previously selected tags
  const inviteMsg = isStepSaved
    ? t("jobs:employers_job_save_skills_modify_msg")
    : t("jobs:employers_job_save_skills_suggestion_msg");

  const onChangeTechSkills = (tags) => {
    setTechSkills(tags);
  };

  const onChangeSoftSkills = (tags) => {
    setSoftSkills(tags);
  };

  const canSubmit = () => {
    return true;
  };

  const submit = () => {
    return mutate({
      variables: {
        id: job._id,
        technicalSkills: techSkills,
        softSkills: softSkills,
        lastSavedStep: isStepSaved ? undefined : STEP_ENUM.SKILLS,
      },
    });
  };

  // When job is reanalyzed or skills are modified elsewhere, check whether we should suggest the skills from analysis
  React.useEffect(() => {
    setTechSkills(initialTechSkills);
    setSoftSkills(initialSoftSkills);
  }, [initialTechSkills, initialSoftSkills]);

  const form = (
    <div className="JobSaveSkillsForm">
      <Row>
        <Col className="invite-msg-col">{inviteMsg}</Col>
      </Row>
      <Row>
        <Col className="skills-col-container">
          <Row>
            <Col className={"label"}>
              {t("jobs:employers_job_save_skills_technical_label")}
            </Col>
          </Row>
          <Row>
            <Col>
              <EditableTags
                tags={techSkills}
                onChangeTags={onChangeTechSkills}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col className={"label"}>
              {t("jobs:employers_job_save_skills_soft_label")}
            </Col>
          </Row>
          <Row>
            <Col>
              <EditableTags
                tags={softSkills}
                onChangeTags={onChangeSoftSkills}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  return {
    form,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: mutationLoading,
    errors,
  };
}
