import {gql} from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "employerAdmin";
const QUERY_NAME = "employerAdminSearch";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($pageSize: Int, $filter: EmployerAdminFilterInput, $sort: EmployerSortEnum) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(pageSize: $pageSize, filter: $filter, sort: $sort) {
        nodes {
          _id
          firstName
          lastName
          phone
          email
          organization {
            _id
            company
            companyPresentation
          }
          isActiveAccount
          createdAt
        }
      }
    }
  }
`;

export default function useQueryEmployerAdminSearch(pageSize, filter, sort) {
  const variables = { pageSize, filter, sort };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
