import React from "react";
import useQueryApplicationById from "./useQueryApplicationById";
import WaitForData from "../../common/data-fetching/WaitForData";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import ApplicationPreview from "./ApplicationPreview";

export default function ApplicationItem(props) {
  const { applicationId } = props;

  const {
    data: application,
    loading:applicationLoading,
    errors: applicationErrors,
  } = useQueryApplicationById(applicationId);

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <WaitForData
          loading={applicationLoading}
          errors={applicationErrors}
          onLoaded={() => (
            <>
              <ApplicationPreview application={application}/>
            </>
          )}
        />
      )}
    />
  );
}
