import {gql} from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobSearch";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($pageSize: Int, $filter: JobFilterInput, $sort: JobSortEnum) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(pageSize: $pageSize, filter: $filter, sort: $sort) {
        nodes {
          _id
          title
          city
          state
          commission
          createdAt
          status
        }
      }
    }
  }
`;

export default function useQueryJobsSearch(pageSize, filter, sort) {
  const variables = { pageSize, filter, sort };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME,{
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
