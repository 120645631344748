import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobUpdate from "./useMutationJobUpdate";
import { STEP_ENUM } from "./JobSaveForm";
import ValidatedInputText from "../../common/widgets/ValidatedInputText";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm";
import RequiredLabel from "../../common/widgets/RequiredLabel";
import "./JobSaveLocationForm.scss";
import JobWorkModeEnum, {getJobWorkModeLabel} from "../JobWorkModeEnum";
import ValidatedInputSelect from "../../common/widgets/ValidatedInputSelect";
import StringUtils from "../../../utils/StringUtils";

export default function useJobSaveLocationForm(job, isStepSaved) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { mutate, loading: mutationLoading, errors } = useMutationJobUpdate();
  useShowLoader(tLoading);

  const {
    control,
    formState: { isValid },
    getValues,
    reset,
    watch,
  } = useValidatedInputForm();

  const canSubmit = () => {
    return isValid;
  };

  const submit = () => {
    const fields = getValues();
    return mutate({
      variables: {
        id: job._id,
        workMode: StringUtils.isNullOrEmpty(fields.workMode) ? null : fields.workMode,
        city: fields.city,
        state: fields.state,
        country: fields.country,
        lastSavedStep: isStepSaved ? undefined : STEP_ENUM.LOCATION,
      },
    });
  };

  // Retrieve translations for work mode options (anything before tLoading is ready won't be actually displayed)
  const jobWorkModeChoices = React.useMemo(() => {
    if (tLoading)
      return [];

    return Object.values(JobWorkModeEnum).map(workMode => ({
      value: workMode,
      label: tLoading ? workMode : getJobWorkModeLabel(t, workMode)
    }))},
    [t, tLoading]);

  // City, state and province are required unless job is fully remote
  const isLocationRequired = watch("workMode") !== JobWorkModeEnum.REMOTE;

  // Force the form to revalidate itself when the remote status requirement change (because the required status of some fields changes as well)
  React.useEffect(() => {
    reset(getValues())
  }, [isLocationRequired, reset, getValues]);

  const form = (
    <div className="JobSaveLocationForm">
      <Row>
        <Col className="location-invite-msg-col">
          {t("jobs:employers_job_save_location_invite_msg")}
        </Col>
      </Row>
      <Row>
        <Col className="input-col-container">
          <Row>
            <Col>
              {t("jobs:employers_job_save_location_work_mode_label")}
            </Col>
          </Row>
          <Row>
            <Col>
              <ValidatedInputSelect
                name={"workMode"}
                control={control}
                choices={jobWorkModeChoices}
                withBlankOption
                defaultValue={job.workMode}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {t("jobs:employers_job_save_location_city_label")}
              {isLocationRequired && <RequiredLabel/>}
            </Col>
          </Row>
          <Row>
            <Col>
              <ValidatedInputText
                name="city"
                type="text"
                required={isLocationRequired}
                control={control}
                defaultValue={job.city}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {t("jobs:employers_job_save_location_state_label")}
              {isLocationRequired && <RequiredLabel/>}
            </Col>
          </Row>
          <Row>
            <Col>
              <ValidatedInputText
                name="state"
                required={isLocationRequired}
                type="text"
                control={control}
                defaultValue={job.state}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {t("jobs:employers_job_save_location_country_label")}
              {(isLocationRequired) && <RequiredLabel/>}
            </Col>
          </Row>
          <Row>
            <Col>
              <ValidatedInputText
                name="country"
                required={isLocationRequired}
                type="text"
                control={control}
                defaultValue={job.country}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  return {
    form,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: mutationLoading,
    errors,
  };
}
