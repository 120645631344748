import React from "react";
import Container from "react-bootstrap/Container";
import "./BasePage.scss";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import HeaderContainer from "./HeaderContainer";
import PageFooter from "../../footer/PageFooter";
import HeaderContainerRefContext from "./HeaderContainerRefContext";
import FooterContainerRefContext from "./FooterContainerRefContext";

/**
 * Displays the simplest version of a page: a header (without the navbar and its menu items because they might
 * depend on the authenticated user and translation), a zone for the contents (to be passed as a child) and a footer.
 * It can be used before fetching any information such as the authenticated user. It can be used to display errors
 * when fetching the authenticated user and other data usually needed to display a full page.
 *
 * Use the header prop to provide a default header that can be displayed while waiting for translation providers,
 * authentication providers, etc. to be ready, or if an error occurs during that process.
 *
 * When all providers are loaded, use HeaderPortal to inject the final header component. The portal will replace the default
 * header passed as a prop.
 *
 * This class cannot be converted to a functional component because we need to create a ref here.
 *
 * The ref is used to fill the header with a navbar (located down one of the children branch (HeaderContainer) of this
 * component) from a component located down another branch of this component (this.props.children).
 *
 * The ref placeholder created here is forwarded to the header component and "instantiated" when displaying the
 * container of the header. The ref is made available to the components down the main contents tree
 * through a context. A HeaderPortal down the tree generates the menu items, retrieves the reference from the context
 * and uses a portal to this reference to fill the header navbar.
 *
 * We use a ref for the footer too, because we need translation to be ready to display the footer, but we want to
 * display the BasePage while waiting for the translation (so that the loader does not move on the page).
 */
export default class BasePage extends React.Component {
  headerContainerRef = React.createRef();
  footerContainerRef = React.createRef();

  render() {
    return (
      <div className="BasePage">
        <ScrollToTopOnMount />
        <nav>
          <HeaderContainer
            ref={this.headerContainerRef}
            header={this.props.header}
          />
        </nav>
        <div className="scrollable-content">
          <Container className="main-container">
            <HeaderContainerRefContext.Provider value={this.headerContainerRef}>
              <FooterContainerRefContext.Provider
                value={this.footerContainerRef}
              >
                {this.props.children}
              </FooterContainerRefContext.Provider>
            </HeaderContainerRefContext.Provider>
          </Container>
          <nav className="footer-nav">
            <PageFooter ref={this.footerContainerRef} />
          </nav>
        </div>
      </div>
    );
  }
}
