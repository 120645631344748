import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import ActionDiv from "../../common/widgets/ActionDiv";
import "./ApplicationPreview.scss";
import HtmlUtils from "../../../utils/HtmlUtils";
import StringUtils from "../../../utils/StringUtils";
import useContentModal from "../../common/modals/useContentModal";
import ApplicationUpdateForm from "../mutate/ApplicationUpdateForm.js";
import PersonIcon from "@material-ui/icons/Person";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationCandidates from "../useTranslationCandidates";
import ThreeStateCheckbox from "../../common/components/ThreeStateCheckbox";
import useMutationApplicationUpdate from "../mutate/useMutationApplicationUpdate";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import useErrorModal from "../../common/modals/useErrorModal";
import ResumeDownloadIcon from "./ResumeDownloadIcon";

const NOTE_LENGTH = 15;

export default function ApplicationPreview(props) {
  const { application, selected, handleApplicationSelect, onFilterChange } =
    props;
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ApplicationPreview");

  const fullNote = HtmlUtils.removeHtml(application.recruiterNote).trim();

  // Modal to show application details form
  const {
    ContentModal: ApplicationUpdateModal,
    show: showApplicationUpdateModal,
    hide: hideApplicationUpdateModal,
  } = useContentModal("ApplicationUpdateModal");

  //si la note est plus grande que NOTE_LENGTH la couper et rajouter des points de suspension
  const truncatedNote =
    fullNote.length > NOTE_LENGTH
      ? fullNote.substring(0, NOTE_LENGTH).trim().concat("\u2026")
      : StringUtils.nullToEmpty(fullNote);

  // ajouter des guillemets si la note n'est pas vide
  const note = truncatedNote.length > 0 ? `"${truncatedNote}"` : "";

  const { mutate, errors } = useMutationApplicationUpdate();
  const errorMsg = useServerErrorFormatter(errors);
  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("candidates:candidates_details_update_error")}</p>
      {errorMsg}
    </>
  );
  const onClickApplication = () => {
    if (!application.viewed) {
      const variables = {};
      variables["id"] = application._id;
      variables["viewed"] = true;
      mutate({ variables }).catch(() => {
        showErrorModal();
      });
    }
    showApplicationUpdateModal(getApplicationUpdateForm());
  };

  const getApplicationUpdateForm = () => {
    return (
      <ApplicationUpdateForm
        onClose={hideApplicationUpdateModal}
        applicationId={application._id}
        onFilterChange={onFilterChange}
      />
    );
  };

  const statusNewLabel = t(
    "candidates:candidates_application_status_new_label"
  );

  return (
    <>
      {ApplicationUpdateModal}
      {ErrorModal}
      <div className="ApplicationPreview">
        <Row className={"name-row"}>
          <Row>
            <Col className="Checkbox-col">
              <ThreeStateCheckbox
                checked={selected}
                onChange={handleApplicationSelect}
                name={application._id}
              />
            </Col>
            <Col className="action-container">
              <Row>
                <Col className="action-col">
                  <div className="link">{application.candidate.name}</div>
                </Col>
              </Row>

              <Row>
                <Col className={"note-col"}>
                  <p className="noteParagraph">{note}</p>
                </Col>
              </Row>
            </Col>
            <Col className="new-badge-col">
              {!application.viewed && (
                <Badge className="new-badge">{statusNewLabel}</Badge>
              )}
            </Col>
            {application.recruiter.isActiveAccount && (
              <Col className={"referrer-col"}>
                <Row>
                  <Col className="referrerLabelContainer">
                    {t("candidates:candidates_referrer_label")}
                  </Col>
                  <Col>
                    <div className="link">
                      {application.recruiter.firstName + "\u00a0"}
                      {application.recruiter.lastName}
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
            <Col className="personIconContainer">
              <ActionDiv onClick={() => onClickApplication()}>
                <PersonIcon />
              </ActionDiv>
            </Col>
            <Col className="downloadContainer">
              {application.resumeId && (
                <ResumeDownloadIcon
                  resumeId={application.resumeId}
                  showLabel={false}
                />
              )}
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}
