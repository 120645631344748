import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import "./JobDashboardApplicationsBlock.scss";
import useTranslationJobDashboard from "../useTranslationJobDashboard";
import JobDashboardApplicationsCount from "./JobDashboardApplicationsCount";
import useQueryStatusGroupAndCount from "../search/useQueryStatusGroupAndCount";
import useQueryDateByValueSearchCount from "../search/useQueryDateGroupAndCount";
import WaitForData from "../../common/data-fetching/WaitForData";
import RouterUtils from "../../../utils/RouterUtils";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import ActionDiv from "../../common/widgets/ActionDiv";
import NewCandidatesByJobCount from "../../jobs/search/NewApplicationByJobCount";
import PageSubtitle from "../../common/widgets/PageSubtitle";
import ApplicationStatusEnum from "../../candidates/ApplicationStatusEnum";

function JobDashboardApplicationsBlock(props) {
  const { job } = props;

  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading, "JobDashboardApplicationsBlock");

  const {
    data: counts,
    loading,
    errors,
  } = useQueryStatusGroupAndCount(job._id);

  const { loading: candidatesByDateLoading, errors: candidatesByDateErrors } =
    useQueryDateByValueSearchCount(job._id);

  const blockTitle = t("job-dashboard:job_dashboard_applications_list_label");
  const statusStackLabel = t(
    "job-dashboard:job_dashboard_applications_status_stack_label"
  );
  const statusShortlistLabel = t(
    "job-dashboard:job_dashboard_applications_status_shortlist_label"
  );
  const statusInterviewLabel = t(
    "job-dashboard:job_dashboard_applications_status_interview_label"
  );

  const getCountForStatus = (status) => {
    const statusItem = counts.find((item) => item.status === status);
    return statusItem ? statusItem["count"] : 0;
  };

  const history = useHistory();
  function onClickStatus(event, jobId, status) {
    event.preventDefault();
    history.push({
      pathname: RouterUtils.injectParamsInRoute(
        ROUTES.EMPLOYER_JOB_CANDIDATES,
        {
          jobId,
        }
      ),
      state: { status },
    });
  }

  return (
    <>
      <WaitForData
        loading={loading || candidatesByDateLoading}
        errors={errors || candidatesByDateErrors}
        onLoaded={() => (
          <div className="JobDashboardApplicationsBlock">
            <PageSubtitle colTitle={blockTitle} />
            <Row className="jobCountRow">
              <Col className="jobCountCol">
                <NewCandidatesByJobCount jobId={job._id} />
              </Col>
            </Row>
            <Row className="actionLinkContainer">
              <Col>
                <Row>
                  <Col>
                    <ActionDiv
                      onClick={(event) =>
                        onClickStatus(event, job._id, ApplicationStatusEnum.STACK)
                      }
                      className={"ApplicationCountAction"}
                    >
                      <JobDashboardApplicationsCount
                        label={statusStackLabel}
                        count={getCountForStatus(ApplicationStatusEnum.STACK)}
                      />
                    </ActionDiv>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ActionDiv
                      onClick={(event) =>
                        onClickStatus(event, job._id, ApplicationStatusEnum.SHORTLIST)
                      }
                      className={"ApplicationCountAction"}
                    >
                      <JobDashboardApplicationsCount
                        label={statusShortlistLabel}
                        count={getCountForStatus(ApplicationStatusEnum.SHORTLIST)}
                      />
                    </ActionDiv>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ActionDiv
                      onClick={(event) =>
                        onClickStatus(event, job._id, ApplicationStatusEnum.INTERVIEW)
                      }
                      className={"ApplicationCountAction"}
                    >
                      <JobDashboardApplicationsCount
                        label={statusInterviewLabel}
                        count={getCountForStatus(ApplicationStatusEnum.INTERVIEW)}
                      />
                    </ActionDiv>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      />
    </>
  );
}

export default JobDashboardApplicationsBlock;
