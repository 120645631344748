import React from "react";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionLink from "../../common/widgets/ActionLink";
import ActionButton from "../../common/widgets/ActionButton";
import StringUtils from "../../../utils/StringUtils";
import useJobSaveTitleForm from "./useJobSaveTitleForm";
import useJobSaveDescriptionForm from "./useJobSaveDescriptionForm";
import useErrorModal from "../../common/modals/useErrorModal";
import "./JobSaveForm.scss";
import useJobSaveSkillsForm from "./useJobSaveSkillsForm";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import useJobSaveLocationForm from "./useJobSaveLocationForm";
import useJobSaveCommissionForm from "./useJobSaveCommissionForm";
import useJobSavePublicationForm from "./useJobSavePublicationForm";
import useJobSaveSalaryForm from "./useJobSaveSalaryForm";
import JobStatusEnum from "../JobStatusEnum";

export const STEP_ENUM = {
  TITLE: "TITLE",
  DESCRIPTION: "DESCRIPTION",
  LOCATION: "LOCATION",
  SKILLS: "SKILLS",
  SALARY: "SALARY",
  COMMISSION: "COMMISSION",
  PUBLICATION: "PUBLICATION",
};

export default function JobSaveForm(props) {
  const { job, step, onChangeStep, onCompleted } = props;

  const { t, loading } = useTranslationJobs();
  useShowLoader(loading);

  // Label of the last action
  const saveLastAction = job.status === JobStatusEnum.PUBLISHED
    ? t("jobs:employers_job_update_last_action")
    : t("jobs:employers_job_save_last_action");

  // Define all the steps of the form
  const stepDefinitions = [{
      name: STEP_ENUM.TITLE,
      label: t("jobs:employers_job_save_title_step_label"),
      ...useJobSaveTitleForm(
        job
      ),
      className: "title-step-container",
      visible: true
    }, {
      name: STEP_ENUM.DESCRIPTION,
      label: t("jobs:employers_job_save_description_step_label"),
      ...useJobSaveDescriptionForm(
        job
      ),
      visible: true
    }, {
      name: STEP_ENUM.SKILLS,
      label: t("jobs:employers_job_save_skills_step_label"),
      ...useJobSaveSkillsForm(
        job
      ),
      visible: true
    }, {
      name: STEP_ENUM.LOCATION,
      label: t("jobs:employers_job_save_location_step_label"),
      ...useJobSaveLocationForm(
        job
      ),
      className: "location-step-container",
      visible: true
    }, {
      name: STEP_ENUM.SALARY,
      label: t("jobs:employers_job_save_employment_step_label"),
      ...useJobSaveSalaryForm(
        job
      ),
      className: "salary-step-container",
      visible: true
    }, {
      name: STEP_ENUM.COMMISSION,
      label: t("jobs:employers_job_save_commission_step_label"),
      ...useJobSaveCommissionForm(
        job
      ),
      className: "commission-step-container",
      visible: true
    }, {
      name: STEP_ENUM.PUBLICATION,
      label: t("jobs:employers_job_save_publication_step_label"),
      ...useJobSavePublicationForm(
        job
      ),
      visible: job.status !== JobStatusEnum.PUBLISHED
    }
  ];

  /**
   * Get the index of the nearest visible step (from stepDefinitions) before the step index provided.
   * The current position is included in the search.
   * @param position Start search at this index
   * @returns {number|*} Index of the nearest visible step before provided position, or -1 if no visible step found
   */
  const getPreviousVisibleStepIndex = (position) => {
    // Move backward from position (including current) until step is visible
    for (let i = position; i >= 0 && i < stepDefinitions.length; i--) {
      if (stepDefinitions[i].visible) {
        return i;
      }
    }

    // We have reached index 0 and haven't encountered a visible step, so return -1 (not found)
    return -1;
  }

  /**
   * Get the index of the nearest visible step (from stepDefinitions) after the step index provided.
   * The current position is included in the search.
   * @param position Start search at this index
   * @returns {number|*} Index of the nearest visible step after provided position, or -1 if no visible step found
   */
  const getNextVisibleStepIndex = (position) => {
    // Move forward from position (including current position) until step is visible
    for (let i = position; i >= 0 && i < stepDefinitions.length; i++) {
      if (stepDefinitions[i].visible) {
        return i;
      }
    }

    // We have reached the maximum index and haven't encountered a visible step, so return -1 (not found)
    return -1;
  }

  /**
   * Get the index of the step (from stepDefinitions) whose name is the one provided. The step may be visible or not.
   * If name is empty, return -1.
   * @param name Step name
   * @returns {number} Step index
   */
  const getStepIndexByName = (name) => {
    if (StringUtils.isNullOrEmpty(name))
      return -1;
    return stepDefinitions.findIndex((stepDefinition) => stepDefinition.name === name.toUpperCase());
  }

  // Find target step index and definition from step name provided in properties. If step is empty / not found, use step 0.
  // If step is not visible, use the nearest visible step before (or step 0 if none found).
  const currentStepIndex = Math.max(
    0,
    getPreviousVisibleStepIndex(getStepIndexByName(step))
  );

  const currentStepDefinition = stepDefinitions[currentStepIndex];

  // Find last saved step in step definition array. If step is empty / not found, use -1. If step is not visible, use the
  // nearest visible step before (or -1 if none found)
  const lastSavedStepIndex = Math.max(-1, getPreviousVisibleStepIndex(getStepIndexByName(job.lastSavedStep)));

  const [lastVisitedStepIndex, setLastVisitedStepIndex] = React.useState(Math.max(currentStepIndex, lastSavedStepIndex));

  React.useEffect(() => {
    if (lastVisitedStepIndex < currentStepIndex)
      setLastVisitedStepIndex(currentStepIndex);
  }, [currentStepIndex, lastVisitedStepIndex]);

  // Form is not ready for display until the form content is ready
  const ready = currentStepDefinition.ready;

  // It is an error to display a step other than the first one if there is no existing job
  const jobNotFound =
    currentStepIndex !== 0 && !job._id ? (
      <p>{t("jobs:employers_job_save_no_job")}</p>
    ) : null;
  const submitError = useServerErrorFormatter(currentStepDefinition.errors);
  const error = jobNotFound || submitError;
  const { ErrorModal, show: showErrorModal } = useErrorModal(error);

  // Get name of previous visible step (excluding current position)
  const getPrevStepName = () => {
    const previousVisibleStepIndex = getPreviousVisibleStepIndex(currentStepIndex - 1);
    return previousVisibleStepIndex === -1 ? "" : stepDefinitions[previousVisibleStepIndex].name;
  };

  // Get name of next visible step (excluding current position)
  const getNextStepName = () => {
    const nextVisibleStepIndex = getNextVisibleStepIndex(currentStepIndex + 1);
    return nextVisibleStepIndex === -1 ? "" : stepDefinitions[nextVisibleStepIndex].name;
  };

  const prevStepName = getPrevStepName();
  const nextStepName = getNextStepName();

  // Tell the parent that the step has changed, in case it wants to change the url
  const onClickStep = (event, stepName) => {
    event.preventDefault();
    onChangeStep(stepName, job._id);
  };

  // Make a link to reach a step directly without using Previous and Next buttons
  const makeStep = (stepDefinition, stepIndex) => {

    const stepLabel = stepDefinition.label;

    const isCurrent = stepIndex === currentStepIndex;
    const isVisited = stepIndex <= lastVisitedStepIndex;
    const isSaved = stepIndex <= lastSavedStepIndex;

    const progressCount = isSaved ? <span className="progress-count"/> : <span className="progress-count-number">{stepIndex + 1}</span>;

    if (isCurrent) {
      // Current step is highlighted and not clickable
      return (
        <>
          {progressCount}
          <span className={"current-step-link progress-label"}>
            {stepLabel}
          </span>
        </>
      );
    } else if (!isVisited) {
      // The steps not visited yet do not have a link
      return (
        <>
          {progressCount}
          <span className="progress-label">{stepLabel}</span>
        </>
      );
    } else {
      // Steps previously visited have a link
      return (
        <>
          {progressCount}
          <span className="progress-span">
            <ActionLink
              className="progress-action"
              onClick={(event) => onClickStep(event, stepDefinition.name)}
            >
              {stepLabel}
            </ActionLink>
          </span>
        </>
      );
    }
  };

  const onClickPrevStep = (event) => {
    event.preventDefault();
    onChangeStep(prevStepName, job._id);
  };

  const onClickNextStep = (event) => {
    event.preventDefault();
    currentStepDefinition
      .submit()
      .then((job) => onChangeStep(nextStepName, job._id))
      .catch((/*error*/) => {
        showErrorModal();
      });
  };

  const onClickLastStep = (event) => {
    event.preventDefault();
    currentStepDefinition
      .submit()
      .then((job) => {
        if (onCompleted) return onCompleted(job);
      })
      .catch((/*error*/) => {
        showErrorModal();
      });
  };

  return (
    <>
      {ErrorModal}
      <form onSubmit={ (event) => onClickNextStep(event)} className="JobSaveForm form-with-rows">
        <Row>
          <div className="step-div">
            <Col className="step-wizard">
              <ul className={"step-wizard-list"}>
                {stepDefinitions.filter(stepDefinition => stepDefinition.visible).map((stepDefinition, stepIndex) => (
                  <li key={stepIndex} className={"step-wizard-item"}>
                    {makeStep(stepDefinition, stepIndex)}
                  </li>
                ))}
              </ul>
            </Col>
          </div>
        </Row>
        <Row className="form-row">
          <Col className={StringUtils.nullToEmpty(currentStepDefinition.className)}>
            {currentStepDefinition.form}
          </Col>
        </Row>
        <Row>
          <Col className={"form-actions"}>
            {!StringUtils.isNullOrEmpty(prevStepName) && (
              <ActionButton onClick={onClickPrevStep}>
                {t("jobs:employers_job_save_previous_action")}
              </ActionButton>
            )}
            {StringUtils.isNullOrEmpty(nextStepName) ? (
              <ActionButton
                onClick={onClickLastStep}
                loading={currentStepDefinition.submitting}
                disabled={!currentStepDefinition.canSubmit() || !ready}
              >
                {saveLastAction}
              </ActionButton>
            ) : (
              <ActionButton
                type="submit"
                loading={currentStepDefinition.submitting}
                disabled={!currentStepDefinition.canSubmit() || !ready}
              >
                {t("jobs:employers_job_save_next_action")}
              </ActionButton>
            )}
          </Col>
        </Row>
      </form>
    </>
  );
}
