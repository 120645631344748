import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import useQueryRecruiterCountByDate from "./useQueryRecruiterCountByDate";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageSubtitle from "../../common/widgets/PageSubtitle";
import RecruiterLineChart from "./CountLineChart";

export default function RecruiterCountByDateItem(props) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "RecruiterCountByDateItem");

  const { data, loading, errors } = useQueryRecruiterCountByDate();

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="RecruiterCountByDateItem">
            <PageSubtitle
              colTitle={t("admin:admin_dashboard_recruiters_count_by_date_title")}
            />
            <Row>
              <Col>
                <RecruiterLineChart data={data }/>
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
