import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationJobDashboard from "../useTranslationJobDashboard";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Form from "react-bootstrap/Form";
import useMutationJobCloseWithoutHire from "./useMutationJobCloseWithoutHire";
import useMutationJobCloseWithHire from "./useMutationJobCloseWithHire";
import useFeedbackModal from "../../common/modals/useFeedbackModal";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import ActionButton from "../../common/widgets/ActionButton";
import useQueryApplicationSearch from "../search/useQueryApplicationSearch";
import Loader from "../../common/loading-widgets/Loader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ThreeStateCheckbox from "../../common/components/ThreeStateCheckbox";
import "./JobCloseForm.scss";

export default function JobCloseForm(props) {
  const { onAbort, onSuccess, job } = props;
  const [hiredCandidates, setHiredCandidates] = useState([]);
  const [isCancelled, setIsCanceled] = useState(false);
  const [isExternalHire, setIsExternalHire] = useState(false);

  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading);

  const { data, loading, errors } = useQueryApplicationSearch(
    job._id,
    20,
    {},
    "DATE_ASC"
  );

  const {
    mutate: mutationJobWithoutHire,
    loading: mutationJobWithoutHireLoading,
    errors: mutationJobWithoutHireErrors,
  } = useMutationJobCloseWithoutHire();

  const {
    mutate: mutationJobWithHire,
    loading: mutationJobWithHireLoading,
    errors: mutationJobWithHireErrors,
  } = useMutationJobCloseWithHire();

  const errorMsg = useServerErrorFormatter(
    mutationJobWithHireErrors || mutationJobWithoutHireErrors
  );
  const { launch, FeedbackModal } = useFeedbackModal(
    undefined,
    errorMsg,
    onSuccess
  );

  const closeJob = () => {
    if (hiredCandidates.length > 0) {
      return mutationJobWithHire({
        variables: {
          id: job._id,
          applicationsId: hiredCandidates,
        },
      });
    } else {
      // Create an empty hire
      return mutationJobWithoutHire({
        variables: {
          id: job._id,
          isCancelled,
          isExternalHire,
        },
      });
    }

  };

  const onSelectCandidateChange = (candidateId) => {
    const index = hiredCandidates.indexOf(candidateId);
    if (index > -1) {
      setHiredCandidates((prevHiredCandidates) =>
        prevHiredCandidates.filter((item, i) => i !== index)
      );
    } else {
      setHiredCandidates((prevHiredCandidates) => [
        ...prevHiredCandidates,
        candidateId,
      ]);
    }
  };

  const onIsCanceledChange = () => {
    setIsCanceled(!isCancelled);
    setIsExternalHire(false);
    setHiredCandidates([]);
  };

  const onIsExternalHireChange = () => {
    setIsExternalHire(!isExternalHire);
    setIsCanceled(false);
    setHiredCandidates([]);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    launch(closeJob);
  };

  const canSubmit = () => {
    return hiredCandidates.length > 0 || isExternalHire || isCancelled;
  };

  return (
    <>
      {FeedbackModal}
      <Loader>
        <WaitForData
          loading={loading}
          errors={errors}
          onLoaded={() => (
            <div className="JobCreate">
              <Form
                onSubmit={onSubmit}
                className={"form-with-rows"}
                autoComplete={"off"}
              >
                <Row>
                  <Col>
                    <h1>{job.title}</h1>
                  </Col>
                </Row>
                {!isCancelled && !isExternalHire && data["nodes"].length > 0 && (
                  <>
                    <Row>
                      <Col className="title-col">
                        {t(
                          "job-dashboard:job_dashboard_job_close_candidate_selection_label"
                        )}
                      </Col>
                    </Row>
                    {data["nodes"].map((item) => (
                      <Row key={item._id}>
                        <Col className="checkbox-col">
                          <ThreeStateCheckbox
                            name={item._id}
                            label={item.candidate.name}
                            onChange={() => onSelectCandidateChange(item._id)}
                          />
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
                <hr />
                <Row>
                  <Col className="checkbox-col">
                    <ThreeStateCheckbox
                      name="isExternalHire"
                      label={t(
                        "job-dashboard:job_dashboard_job_close_is_external_hire_label"
                      )}
                      onChange={onIsExternalHireChange}
                      checked={isExternalHire}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="checkbox-col">
                    <ThreeStateCheckbox
                      name="isCancelled"
                      label={t(
                        "job-dashboard:job_dashboard_job_close_is_canceled_label"
                      )}
                      onChange={onIsCanceledChange}
                      checked={isCancelled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className={"form-actions"}>
                    <ActionButton onClick={onAbort}>
                      {t("job-dashboard:job_dashboard_job_close_cancel_action")}
                    </ActionButton>
                    <ActionButton
                      loading={mutationJobWithoutHireLoading || mutationJobWithHireLoading}
                      type={"submit"}
                      disabled={!canSubmit()}
                    >
                      {t("job-dashboard:job_dashboard_job_close_submit_action")}
                    </ActionButton>
                  </Col>
                </Row>
                <Row>
                  <Col className={"form-label"}>
                    *{t("job-dashboard:job_dashboard_job_close_alert_label")}
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        />
      </Loader>
    </>
  );
}
