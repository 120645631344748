import {gql} from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobById";

export const JOB_TYPE_ALL_FIELDS_FRAGMENT = gql`
  fragment JobResponsibilityTypeAllFieldsFragment on JobResponsibilityType {
    _id, actionVerbs, nounPhrases, responsibility
  }

  fragment JobTypeAllFieldsFragment on JobType {
    _id, employerId, title, description, streetAddress, postalCode, city, state, country, datePosted,
    validThrough, employmentType, baseSalaryValue, baseSalaryUnitText, seniority, commission,
    status, employerNote, paymentStatus, technicalSkills, softSkills, workMode, isWorkPermitRequired, lastSavedStep, isCancelled, isExternalHire,
    titleSynonyms, nlpJobTitles, nlpSeniority, nlpRequiredLanguages,
    nlpRequiredTechnicalSkills, nlpRequiredSoftSkills,
    nlpResponsibilities {...JobResponsibilityTypeAllFieldsFragment},
    nlpQualifications, nlpRequiredDiploma, nlpQuery, nlpMatch, createdAt, createdBy, updatedAt, updatedBy
  }
`;

export const QUERY = gql`
  ${JOB_TYPE_ALL_FIELDS_FRAGMENT}
  query ${QUERY_SCOPE}_${QUERY_NAME}($id: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(id: $id) {
        ...JobTypeAllFieldsFragment
      }
    }
  }
`;

export default function useQueryJobById(id) {

  const variables = {id};

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
    skip: id == null
  });
}
