import React from "react";
import JobSaveForm from "./JobSaveForm";
import useQueryJobById from "../view/useQueryJobById";
import WaitForData from "../../common/data-fetching/WaitForData";
import RouterUtils from "../../../utils/RouterUtils";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";

export default function JobUpdateForm(props) {
  const { jobId, step, onChangeStep } = props;
  const history = useHistory();

  const { data: job, loading, errors } = useQueryJobById(jobId);

  const onCompleted = (job) => {
    history.push(
      RouterUtils.injectParamsInRoute(ROUTES.EMPLOYER_JOB_DASHBOARD, {
        jobId: job._id,
      })
    );
  };

  return (
    <WaitForData
      loading={loading}
      errors={errors}
      onLoaded={() => (
        <JobSaveForm
          job={job}
          step={step}
          onChangeStep={onChangeStep}
          onCompleted={onCompleted}
        />
      )}
    />
  );
}
