import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ActionDiv from "../../common/widgets/ActionDiv";
import "./ApplicationPreview.scss";
import useContentModal from "../../common/modals/useContentModal";
import ApplicationUpdateForm from "../../candidates/mutate/ApplicationUpdateForm";
import PersonIcon from "@material-ui/icons/Person";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobDashboard from "../useTranslationJobDashboard";
import useMutationCandidateUpdate from "../../candidates/mutate/useMutationApplicationUpdate";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import useErrorModal from "../../common/modals/useErrorModal";
import ResumeDownloadIcon from "../../candidates/view/ResumeDownloadIcon";

export default function ApplicationPreview(props) {
  const { application } = props;

  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading, "ApplicationPreview");

  // Modal to show application details form
  const {
    ContentModal: ApplicationUpdateModal,
    show: showApplicationUpdateModal,
    hide: hideApplicationUpdateModal,
  } = useContentModal("ApplicationUpdateModal");

  const { mutate, errors } = useMutationCandidateUpdate();
  const errorMsg = useServerErrorFormatter(errors);
  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("job-dashboard:job_dashboard_details_update_error")}</p>
      {errorMsg}
    </>
  );
  const onClickApplication = () => {
    if (!application.viewed) {
      const variables = {};
      variables["id"] = application._id;
      variables["viewed"] = true;
      mutate({ variables }).catch(() => {
        showErrorModal();
      });
    }
    showApplicationUpdateModal(getApplicationUpdateForm());
  };

  const getApplicationUpdateForm = () => {
    return (
      <ApplicationUpdateForm
        onClose={hideApplicationUpdateModal}
        applicationId={application._id}
      />
    );
  };

  return (
    <>
      {application && (
        <>
          {ApplicationUpdateModal}
          {ErrorModal}
          <div className="ApplicationPreview">
            <Row className={"name-row"}>
              <Col className="candidate-name-col">
                <div className="candidate-name">
                  {application.candidate.name}
                </div>
              </Col>
              {application.recruiter.isActiveAccount && <Col className={"referrer-col"}>
                {t("job-dashboard:job_dashboard_referrer_label")}
                <div className="ref-name">
                  {application.recruiter.firstName}{" "}
                  {application.recruiter.lastName}
                </div>
              </Col>}
              <Col className="action-icon">
                <ActionDiv onClick={() => onClickApplication()}>
                  <PersonIcon />
                </ActionDiv>
              </Col>
              {application.candidate.resumeId && (
                <Col className="action-icon">
                  <ResumeDownloadIcon
                    resumeId={application.resumeId}
                    showLabel={false}
                  />
                </Col>
              )}
            </Row>
          </div>
        </>
      )}
    </>
  );
}
