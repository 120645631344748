import React from "react";
import ProfileInlineEditInputText from "../mutate/ProfileInlineEditInputText";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useTranslationProfile from "../useTranslationProfile";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import ProfileDeleteButton from "../mutate/ProfileDeleteButton";
import UpdatePasswordButton from "../../authentication-ui/UpdatePasswordButton";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import useQueryEmployerById from "./useQueryEmployerById";
import WaitForData from "../../common/data-fetching/WaitForData";
import "./ProfileBlock.scss";
import { MAX_LENGTH_COMPANY_DESCRIPTION } from "../../../constants/ui";
import RequiredLabel from "../../common/widgets/RequiredLabel";
import FormLabel from "react-bootstrap/esm/FormLabel";
import FormGroup from "react-bootstrap/esm/FormGroup";
import PageSubtitle from "../../common/widgets/PageSubtitle";
import OrganizationInlineEditInputText from "../../organization/mutate/OrganizationInlineEditInputText";
import OrganizationInlineEditInputWysiwyg from "../../organization/mutate/OrganizationInlineEditWysiwyg";

export default function ProfileBlock(props) {
  const { profile: employer } = props;

  const {
    data: profile,
    loading: employerLoading,
    errors: employerErrors,
  } = useQueryEmployerById(employer._id);
  const { t, loading: tLoading } = useTranslationProfile();
  useShowLoader(tLoading, "ProfileBlock");

  const blockTitlePersonal = t("profile:employers_profile_perso_title");
  const blockTitleCompany = t("profile:employers_profile_company_title");

  return (
    <WaitForData
      loading={employerLoading}
      errors={employerErrors}
      onLoaded={() => (
        <>
          <div className="ProfileBlock">
            <Row className="personalInformation">
              <Col>
                <PageSubtitle colTitle={blockTitlePersonal} />
                <FormGroup>
                  <FormLabel>
                    {t("profile:employers_profile_name_msg")}
                    <RequiredLabel />
                  </FormLabel>
                  <Row>
                    <Col className="displayName">
                      <ProfileInlineEditInputText
                        profile={profile}
                        className={"data"}
                        viewAs="span"
                        property={"firstName"}
                        placeholder={t(
                          "profile:employers_profile_firstname_placeholder"
                        )}
                        required
                      />
                    </Col>
                    <Col>
                      <ProfileInlineEditInputText
                        profile={profile}
                        className={"data"}
                        viewAs="span"
                        property={"lastName"}
                        placeholder={t(
                          "profile:employers_profile_lastname_placeholder"
                        )}
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <FormGroup>
                <FormLabel>
                  {t("profile:employers_profile_contact_information_msg")}
                  <RequiredLabel />
                </FormLabel>
                <Row>
                  <Col className="icon-col">
                    <PhoneIcon />
                  </Col>
                  <Col>
                    <ProfileInlineEditInputText
                      profile={profile}
                      className={"data"}
                      property={"phone"}
                      placeholder={t(
                        "profile:employers_profile_phone_placeholder"
                      )}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="icon-col">
                    <EmailIcon />
                  </Col>
                  <Col className={"data"}>{profile.email}</Col>
                </Row>
              </FormGroup>
            </Row>

            <Row>
              <Col>
                <div className="companyInformation">
                  <Row>
                    <PageSubtitle colTitle={blockTitleCompany} />
                    <Col>
                      <FormGroup>
                        <FormLabel>
                          {t("profile:employers_profile_company_msg")}
                          <RequiredLabel />
                        </FormLabel>
                        <OrganizationInlineEditInputText
                          organization={profile.organization}
                          className={"data"}
                          viewAs="span"
                          property={"company"}
                          placeholder={t(
                            "profile:employers_profile_company_placeholder"
                          )}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <FormLabel>
                          {t("profile:employers_profile_url_msg")}
                          <RequiredLabel />
                        </FormLabel>
                        <OrganizationInlineEditInputText
                          organization={profile.organization}
                          className={"data"}
                          viewAs="span"
                          property={"companyUrl"}
                          placeholder={t(
                            "profile:employers_profile_url_placeholder"
                          )}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <FormLabel>
                      {t("profile:employers_profile_contact_information_msg")}
                      <RequiredLabel />
                    </FormLabel>
                    <Row>
                      <Col className="icon-col">
                        <PhoneIcon />
                      </Col>
                      <Col>
                        <ProfileInlineEditInputText
                          profile={profile}
                          className={"data"}
                          property={"phone"}
                          placeholder={t(
                            "profile:employers_company_phone_placeholder"
                          )}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="icon-col">
                        <EmailIcon />
                      </Col>
                      <Col>
                        <OrganizationInlineEditInputText
                          organization={profile.organization}
                          className={"data"}
                          viewAs="span"
                          property={"companyEmail"}
                          placeholder={t(
                            "profile:employers_company_email_placeholder"
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="icon-col">
                        <LinkedInIcon />
                      </Col>
                      <Col>
                        <OrganizationInlineEditInputText
                          organization={profile.organization}
                          className={"data"}
                          viewAs="span"
                          property={"companyLinkedinUrl"}
                          placeholder={t(
                            "profile:employers_company_linkedin_url_placeholder"
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="icon-col">
                        <FacebookIcon />
                      </Col>
                      <Col>
                        <OrganizationInlineEditInputText
                          organization={profile.organization}
                          className={"data"}
                          viewAs="span"
                          property={"companyFacebookUrl"}
                          placeholder={t(
                            "profile:employers_company_facebook_url_placeholder"
                          )}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup>
                        <FormLabel>
                          {t("profile:employers_company_tagline_fr_msg")}
                          <RequiredLabel />
                        </FormLabel>
                        <OrganizationInlineEditInputText
                          organization={profile.organization}
                          className={"data"}
                          viewAs="span"
                          property={"companyCoverTagLineFr"}
                          placeholder={t(
                            "profile:employers_company_tagline_fr_placeholder"
                          )}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <FormLabel>
                          {t("profile:employers_company_tagline_en_msg")}
                          <RequiredLabel />
                        </FormLabel>
                        <OrganizationInlineEditInputText
                          organization={profile.organization}
                          className={"data"}
                          viewAs="span"
                          property={"companyCoverTagLineEn"}
                          placeholder={t(
                            "profile:employers_company_tagline_en_placeholder"
                          )}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <FormLabel>
                          {t("profile:employers_company_intro_fr_msg")}
                          <RequiredLabel />
                        </FormLabel>
                        <OrganizationInlineEditInputWysiwyg
                          name={"description"}
                          required
                          maxLength={MAX_LENGTH_COMPANY_DESCRIPTION}
                          organization={profile.organization}
                          property={"companyIntroFr"}
                          placeholder={t(
                            "profile:employers_company_intro_fr_placeholder"
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <FormLabel>
                          {t("profile:employers_company_intro_en_msg")}
                          <RequiredLabel />
                        </FormLabel>
                        <OrganizationInlineEditInputWysiwyg
                          name={"description"}
                          required
                          maxLength={MAX_LENGTH_COMPANY_DESCRIPTION}
                          organization={profile.organization}
                          property={"companyIntroEn"}
                          placeholder={t(
                            "profile:employers_company_intro_en_placeholder"
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="deleteUpdateContainer">
              <Row>
                <Col>
                  <UpdatePasswordButton employerId={profile._id} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProfileDeleteButton employerId={profile._id} />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    />
  );
}
