// Don't forget to add localized labels for every option
const SalaryUnitEnum = {
  HOUR: "HOUR",
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR"
};

export default SalaryUnitEnum;

/**
 * Return the translation for a given enum value.
 * @param t Translation function
 * @param code Work mode enum value
 * @returns {*} Translation string
 */
export function getSalaryUnitLabel(t, code) {
  // The method receives a t function and repeats "namespace:" in front of every translation key so that
  // the following keys are extracted when running scan-translations.js script (or "npm run scan-translations").
  // Without the t(), the keys wouldn't be recognized as translation keys.

  switch (code) {
      case SalaryUnitEnum.HOUR:
        return t("jobs:salary_unit_hour_label");
      case SalaryUnitEnum.DAY:
        return t("jobs:salary_unit_day_label");
      case SalaryUnitEnum.WEEK:
        return t("jobs:salary_unit_week_label");
      case SalaryUnitEnum.MONTH:
        return t("jobs:salary_unit_month_label");
      case SalaryUnitEnum.YEAR:
        return t("jobs:salary_unit_year_label");
      default:
        return code;
  }
}
