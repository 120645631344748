import React from "react";
import JobSaveForm from "./JobSaveForm";
import {useHistory} from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import RouterUtils from "../../../utils/RouterUtils";

export default function JobCreateForm(props) {

  const {employerId, onChangeStep} = props;
  const history = useHistory();

  // Make a fake job
  const job = {
    employerId
  }

  const onCompleted = job => {
    history.push(RouterUtils.injectParamsInRoute(ROUTES.EMPLOYER_JOB_DASHBOARD, {jobId: job._id}))
  }

  return <JobSaveForm job={job} onChangeStep={onChangeStep} onCompleted={onCompleted}/>
}
