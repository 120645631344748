import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Table from "react-bootstrap/Table";
import useQueryJobAdminSearch from "../search/useQueryJobAdminSearch";
import WaitForData from "../../common/data-fetching/WaitForData";
import ApprovedApplicationByJobCountItem from "../search/ApprovedApplicationByJobCountItem";
import DisplayDate from "../../common/widgets/DisplayDate";

export default function ApplicationByJobCountList(/*props*/) {
  const {
    data: jobs,
    loading,
    errors,
  } = useQueryJobAdminSearch(
    50,
    {
      status: "PUBLISHED",
    },
    "CREATED_AT_DESC"
  );

  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading || loading, "ApplicationByJobCountList");

  const columnsLabels = [
    t("admin:admin_job_title_column_label"),
    t("admin:admin_job_company_column_label"),
    t("admin:admin_job_date_column_label"),
    t("admin:admin_job_application_payment_column_label"),
    t("admin:admin_job_application_count_column_label"),
  ];

  return (
    <>
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <Row className="widget-block">
            <Col>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    {columnsLabels.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {jobs["nodes"].map((item, i) => (
                    <tr key={i}>
                      <th>{i + 1}</th>
                      <th>{item.title}</th>
                      <th>{item.companyName}</th>
                      <th>
                        <DisplayDate date={item.datePosted} />
                      </th>
                      <th>{item.paymentStatus ? item.paymentStatus : ""}</th>
                      <th>
                        <ApprovedApplicationByJobCountItem jobId={item._id} />
                      </th>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      />
    </>
  );
}
