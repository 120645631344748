const JobStatusEnum = {
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  PUBLISHED: "PUBLISHED",
  REJECTED: "REJECTED",
  ARCHIVED: "ARCHIVED",
};

export default JobStatusEnum;

/**
 * Return the translation for a given enum value.
 * @param t Translation function
 * @param code Enum value
 * @returns {*} Translation string
 */
export function getJobStatusLabel(t, code) {
  // translation may not be ready
  if (!t)
    return code;

  // The method receives a t function and repeats "namespace:" in front of every translation key so that
  // the following keys are extracted when running scan-translations.js script (or "npm run scan-translations").
  // Without the t(), the keys wouldn't be recognized as translation keys.

  switch (code) {
    case JobStatusEnum.DRAFT:
      return t("jobs:job_status_draft_label");
    case JobStatusEnum.PENDING:
      return t("jobs:job_status_pending_label");
    case JobStatusEnum.PUBLISHED:
      return t("jobs:job_status_published_label");
    case JobStatusEnum.REJECTED:
      return t("jobs:job_status_rejected_label");
    case JobStatusEnum.ARCHIVED:
      return t("jobs:job_status_archived_label");
    default:
      return code;
  }
}
