import {InMemoryCache} from "@apollo/client";

/**
 * Create the caching strategy for Apollo client.
 */
export function apolloCacheManager() {
  return new InMemoryCache({
    typePolicies: {
    }
  })
}
