import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationCandidates from "../useTranslationCandidates";
import { Breadcrumb } from "react-bootstrap";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import RouterUtils from "../../../utils/RouterUtils";
import ActionLink from "../../common/widgets/ActionLink";
import CandidatesList from "./CandidatesList";
import "./CandidatesBlock.scss";
import useQueryStatusGroupAndCount from "../search/useQueryStatusGroupAndCount";
import WaitForData from "../../common/data-fetching/WaitForData";
import CandidatesFilter from "./CandidatesFilter";

function CandidatesBlock(props) {
  const { job, statusFilter } = props;
  const [filter, setFilter] = useState(statusFilter);

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidatesBlock");
  const history = useHistory();

  const {
    data: counts,
    loading,
    errors,
  } = useQueryStatusGroupAndCount(job._id);

  function onClickDashboardLink(event, jobId) {
    event.preventDefault();
    history.push(
      RouterUtils.injectParamsInRoute(ROUTES.EMPLOYER_DASHBOARD, {
        jobId,
      })
    );
  }

  function onClickJobDashboardLink(event, jobId) {
    event.preventDefault();
    history.push(
      RouterUtils.injectParamsInRoute(ROUTES.EMPLOYER_JOB_DASHBOARD, {
        jobId,
      })
    );
  }

  React.useEffect(() => {
    setFilter(statusFilter);
  }, [statusFilter]);

  return (
    <div className="CandidatesBlock">
      <Row>
        <Breadcrumb>
          <Breadcrumb.Item>
            <ActionLink
              as={"div"}
              variant="outline-primary"
              onClick={(event) => onClickDashboardLink(event, job._id)}
              className={"CandidatesBlock"}
            >
              {t("candidates:candidates_dashboard_link")}
            </ActionLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <ActionLink
              as={"div"}
              variant="outline-primary"
              onClick={(event) => onClickJobDashboardLink(event, job._id)}
              className={"CandidatesBlock"}
            >
              {job.title}
            </ActionLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t("candidates:candidates_label")}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col>
          <h1>{job.title}</h1>
        </Col>
      </Row>
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <Row>
            <Col className="filter-col">
              <CandidatesFilter
                filter={filter}
                counts={counts}
                onFilterClick={setFilter}
              />
            </Col>
          </Row>
        )}
      />
      <Row>
        <CandidatesList
          jobId={job._id}
          status={filter}
          onFilterChange={setFilter}
        />
      </Row>
    </div>
  );
}

export default CandidatesBlock;
