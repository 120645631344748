import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "employerAlert";
const QUERY_NAME = "employerAlertUpdate";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
        $id: ID!,
        $status: EmployerAlertStatusEnum
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          id: $id,
          status: $status
        ) {
          _id
          status
        }
      }
    }
  `;

export default function useMutationJobAlertUpdate() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
