import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import "./JobDashboardSummaryBlock.scss";
import useTranslationJobDashboard from "../useTranslationJobDashboard";
import { Button } from "react-bootstrap";
import StringUtils from "../../../utils/StringUtils";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import RouterUtils from "../../../utils/RouterUtils";
import useContentModal from "../../common/modals/useContentModal";
import JobCloseForm from "../mutate/JobCloseForm";
import HiredCandidatesBlock from "./HiredCandidatesBlock";
import RefreshButton from "../../common/widgets/RefreshButton";
import useMutationJobDelete from "../mutate/useMutationJobDelete";
import useFeedbackModal from "../../common/modals/useFeedbackModal";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import { Badge } from "react-bootstrap";
import JobStatusEnum from "../../jobs/JobStatusEnum";
import {getEmploymentTypeLabel} from "../../jobs/EmploymentTypeEnum";
import {getSalaryUnitLabel} from "../../jobs/SalaryUnitEnum";
import TypeUtils from "../../../utils/TypeUtils";

function JobDashboardSummaryBlock(props) {
  const { job } = props;
  const history = useHistory();

  const isPublished = job.status === JobStatusEnum.PUBLISHED;
  const isArchived = job.status === JobStatusEnum.ARCHIVED;
  const isDraft = job.status === JobStatusEnum.DRAFT;
  const isPending = job.status === JobStatusEnum.PENDING;

  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading);

  const onClickUpdate = (event) => {
    event.preventDefault();
    history.push(
      RouterUtils.injectParamsInRoute(ROUTES.EMPLOYER_JOB_UPDATE, {
        jobId: job._id,
      })
    );
  };

  const addressLine = TypeUtils.arrayRemoveNullOrEmpty([job.city, job.state, job.country])
    .join(", ");

  const salary = parseInt(job.baseSalaryValue);
  const salaryUnitLabel = getSalaryUnitLabel(t, job.baseSalaryUnitText);
  const salaryLine =
    salary > 0 && !StringUtils.isNullOrEmpty(salaryUnitLabel)
      ? `$ ${salary} ${t(
          "job-dashboard:job_dashboard_salary_per_label"
        )} ${salaryUnitLabel}`
      : "";

  // Modal to close a job opening
  const {
    ContentModal: JobCloseModal,
    show: showJobCloseModal,
    hide: hideJobCloseModal,
  } = useContentModal("JobCloseForm");

  const onClickCloseJob = () => {
    showJobCloseModal(getJobCloseForm());
  };

  const getJobCloseForm = () => {
    return (
      <JobCloseForm
        onAbort={hideJobCloseModal}
        onSuccess={hideJobCloseModal}
        job={job}
      />
    );
  };

  // Delete a draft job
  const {
    mutate: deleteMutation,
    loading: deleteLoading,
    errors: deleteErrors,
  } = useMutationJobDelete();

  const deleteProfile = () => {
    return deleteMutation({
      variables: {
        id: job._id,
      },
    });
  };

  const onDeleteCompleted = () => {
    history.push(ROUTES.EMPLOYER_ACTIVE_JOBS);
  };

  const successMsg = (
    <p>{t("job-dashboard:job_dashboard_delete_success_msg")}</p>
  );
  const errorMsg = useServerErrorFormatter(deleteErrors);
  const confirmMsg = (
    <p>{t("job-dashboard:job_dashboard_delete_confirm_msg")}</p>
  );
  const { launch, FeedbackModal } = useFeedbackModal(
    successMsg,
    errorMsg,
    onDeleteCompleted,
    confirmMsg
  );

  const onClickDeleteJob = () => {
    launch(deleteProfile);
  };

  return (
    <>
      {JobCloseModal}
      {FeedbackModal}
      <div className="JobDashboardSummaryBlock">
        <Row>
          <Col className="job-information-col">
            <Row>
              <Col>
                {(job.city || job.state || job.country) && (
                  <Row>
                    <Col>{addressLine}</Col>
                  </Row>
                )}
                {salaryLine && (
                  <Row>
                    <Col>{salaryLine}</Col>
                  </Row>
                )}
                <Row>
                  <Col>{getEmploymentTypeLabel(t, job.employmentType)}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col className="job-detail">
                    {isDraft && (
                      <Row>
                        <Col>
                          <Badge className="draft-mention">
                            {t(
                              "job-dashboard:job_dashboard_draft_mention_text"
                            )}
                          </Badge>
                        </Col>
                      </Row>
                    )}
                    {isPublished && (
                      <Row>
                        <Col>
                          <Badge className="published-mention">
                            {t(
                              "job-dashboard:job_dashboard_published_mention_text"
                            )}
                          </Badge>
                        </Col>
                      </Row>
                    )}
                    {isPending && (
                      <Row className="warning-row">
                        <Col className={"button-col"}>
                          <Badge className="pending-mention">
                            {t("job-dashboard:job_dashboard_pending_job_label")}
                          </Badge>
                        </Col>
                      </Row>
                    )}
                    {isArchived && (
                      <Row className="warning-row">
                        <Col className={"button-col"}>
                          <Badge className="closed-mention">
                            {t("job-dashboard:job_dashboard_closed_job_label")}
                          </Badge>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {!isArchived && (
            <>
              <Col className={"link-col"}>
                <RefreshButton />
              </Col>
              <Col className={"button-col"}>
                <Button onClick={onClickUpdate}>
                  {t("job-dashboard:job_dashboard_update_job_button_label")}
                </Button>
              </Col>
              {isPublished && (
                <Col className={"button-col"}>
                  <Button onClick={() => onClickCloseJob()}>
                    {t("job-dashboard:job_dashboard_close_job_button_label")}
                  </Button>
                </Col>
              )}
              {!isPublished && (
                <Col className={"button-col"}>
                  <Button onClick={() => onClickDeleteJob()}>
                    {t("job-dashboard:job_dashboard_delete_job_button_label")}
                  </Button>
                </Col>
              )}
            </>
          )}
        </Row>
        {isArchived && (
          <Row>
            <Col>
              <HiredCandidatesBlock jobId={job._id} loading={deleteLoading} />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default JobDashboardSummaryBlock;
