import React from "react";
import Page from "../../common/page/Page";
import {useHistory, useLocation, useParams} from "react-router-dom";
import queryString from "query-string";
import JobUpdateForm from "./JobUpdateForm";
import useQueryJobById from "../view/useQueryJobById";
import WaitForData from "../../common/data-fetching/WaitForData";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import ScrollUtils from "../../common/page/ScrollUtils";

export default function JobUpdatePage(/*props*/) {

  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  // Current step
  const step = queryString.parse(location.search).step;

  // JobId
  const jobId = params.jobId;

  // Callback when one of the form components wants to change to another page. We want the URL to change so that
  // the browser's back button works, that's why we push another page to the history.
  const changeStep = (step) => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({step})
    });
    ScrollUtils.scrollToTop();
  }

  const {data: job, loading, errors} = useQueryJobById(jobId);

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <WaitForData
          loading={loading}
          errors={errors}
          onLoaded={() => (
            <Page className="JobUpdatePage">
              <Page.Title loading={loading}>{job.title}</Page.Title>
              <Page.Content>
                <JobUpdateForm
                  jobId={jobId}
                  step={step}
                  onChangeStep={changeStep}
                />
              </Page.Content>
            </Page>
          )}
        />
      )}
    />
  );
}
