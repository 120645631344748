import React from "react";
import { WithContext as ReactTags } from "react-tag-input";
import useTranslationJobs from "../useTranslationJobs";
import "./EditableTags.scss";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import TypeUtils from "../../../utils/TypeUtils";

const KeyCodes = {
  TAB: 9,
  ENTER: 13,
  COMMA: 188,
};

const delimiters = [KeyCodes.TAB, KeyCodes.COMMA, KeyCodes.ENTER];

// Convert a textual tag to a tag object required for ReactTags component
const formatInputTag = tag => ({ id: tag, text: tag });

// Convert a tag object as manipulated by ReactTags component to a textual tag
const formatOutputTag = tagItem => tagItem.text;

/**
 * Display tags
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function EditableTags(props) {
  const { tags, onChangeTags } = props;
  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "EditableTags");

  const handleDelete = (i) => {
    onChangeTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tagItem) => {
    onChangeTags([...tags, formatOutputTag(tagItem)]);
  };

  const handleInputBlurAddition = (tagItem) => {
    if (tagItem) {
      onChangeTags([...tags, tagItem]);
    }
  };

  const handleDrag = (tagItem, currPos, newPos) => {
    // Make a copy of tag array and move tag from old position to new position
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, formatOutputTag(tagItem));
    onChangeTags(newTags);
  };

  const onTagUpdate = (i, newTag) => {
    const updatedTags = tags.slice();
    updatedTags.splice(i, 1, formatOutputTag(newTag));
    onChangeTags(updatedTags);
  };

  return (
    <div className="EditableTags">
      <ReactTags
        tags={TypeUtils.ensureArray(tags).map(tag => formatInputTag(tag))}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        handleInputBlur={handleInputBlurAddition}
        delimiters={delimiters}
        placeholder={t("jobs:employers_job_details_tag_placeholder")}
        onTagUpdate={onTagUpdate}
        editable={true}
        allowDeleteFromEmptyInput={false}
      />
    </div>
  );
}
