import React from "react";
import Page from "../../common/page/Page";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import DetailedJobListBlock from "./DetailedJobListBlock";


export default function DetailedJobListPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "DetailedJobListPage");
  const location = useLocation()

  const filter = queryString.parse(location.search);

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="DetailedJobListPage">
          <Page.Title
            text={t("admin:admin_job_list_title")}
            loading={tLoading}
          />
          <Page.Content>
            <DetailedJobListBlock filter={filter}/>
          </Page.Content>
        </Page>
      )}
    />
  );
}
