import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RouterUtils from "../../../utils/RouterUtils";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import "./JobSearchResult.scss";
import ActionButton from "../../common/widgets/ActionButton";
import CandidateByJobCount from "./ApplicationByJobCount";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../utils/StringUtils";
import DisplayDate from "../../common/widgets/DisplayDate";
import TypeUtils from "../../../utils/TypeUtils";

export default function JobSearchResult(props) {
  const { item: job } = props;
  const history = useHistory();

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobSearchResult");

  function onClickJob(event, jobId) {
    event.preventDefault();
    history.push(
      RouterUtils.injectParamsInRoute(ROUTES.EMPLOYER_JOB_DASHBOARD, {
        jobId,
      })
    );
  }

  const commission = job.commission;

  // Country is not useful for employers and space is limited
  const addressLine = TypeUtils.arrayRemoveNullOrEmpty([job.city, job.state])
    .join(", ");

  return (
    <>
      <ActionButton
        as={"div"}
        variant="outline-primary"
        onClick={(event) => onClickJob(event, job._id)}
        className="JobSearchResult"
      >
        <Row
          className={"JobSearchResult"}
        >
          <Col>
            <Row className={"job-title"}>
              <Col>{job.title}</Col>
            </Row>
            <Row>
              <Col className={"job-location"}>{addressLine}</Col>
            </Row>
          </Col>
          <Col className={"date-col"}>
            <DisplayDate date={job.createdAt} />
          </Col>
          <Col className="job-commission">
            {commission > 0 && (
              <span className="commission-span">
                {t("jobs:employers_job_dollars", {
                  amount: StringUtils.nullToZero(commission),
                })}
              </span>
            )}
          </Col>
          <Col className={"job-count"}>
            <CandidateByJobCount jobId={job._id} />
          </Col>
        </Row>
      </ActionButton>
    </>
  );
}
