import React from "react";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import LastViewedList from "../../common/components/LastViewedList";
import JobSearchResult from "../search/JobSearchResult";
import useQueryJobsSearch from "../../dashboard/search/useQueryJobsSearch";

export default function JobsList(props) {
  const { employerId, status, sort } = props;

  const { data, loading, errors } = useQueryJobsSearch(
    undefined, // there is a maximum hardcoded in the API, consider implementing pagination
    {
      employerId,
      status
    },
    sort
  );

  const items = (data && data.nodes) ? data.nodes : [];
  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "LastViewedJobs");

  const emptyMsg = t("jobs:employers_search_empty_active_job_list_msg");
  const getItemId = (job) => job._id;

  const columnsLabels = [
    t("jobs:employers_job_title_column_label"),
    t("jobs:employers_job_date_column_label"),
    t("jobs:employers_job_commission_column_label"),
    t("jobs:employers_job_number_candidates_column_label"),
    t("jobs:employers_job_notifications_column_label"),
  ];

  return (
    <LastViewedList
      items={items}
      loading={loading}
      errors={errors}
      className={"LastViewedJobs"}
      columnsLabels={columnsLabels}
      emptyMsg={emptyMsg}
      getItemId={getItemId}
      SearchResult={<JobSearchResult />}
    />
  );
}
