import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import RefreshButton from "../../common/widgets/RefreshButton";
import "./DetailedJobListBlock.scss";
import DetailedJobList from "./DetailedJobList";

function DetailedJobListBlock({filter}) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "DetailedJobListBlock");

  return (
    <div className="DetailedJobListBlock">
      <Row>
        <Col>
          <h1>{t("admin:admin_job_list_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <RefreshButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <DetailedJobList filter={filter}/>
        </Col>
      </Row>
    </div>
  );
}

export default DetailedJobListBlock;
