import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "organization";
const QUERY_NAME = "organizationUpdate";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
      $id: ID!,
      $company: String
      $companyUrl: String
      $companyPresentation: String
      $companyIntroFr: String
      $companyIntroEn: String
      $companyPhone: String
      $companyEmail: String
      $companyFacebookUrl: String
      $companyLinkedinUrl: String
      $companyCoverTagLineFr: String
      $companyCoverTagLineEn: String
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          id: $id,
          company: $company,
          companyUrl: $companyUrl,
          companyPresentation: $companyPresentation,
          companyIntroFr: $companyIntroFr,
          companyIntroEn: $companyIntroEn,
          companyPhone: $companyPhone,
          companyEmail: $companyEmail,
          companyFacebookUrl: $companyFacebookUrl,
          companyLinkedinUrl: $companyLinkedinUrl,
          companyCoverTagLineFr: $companyCoverTagLineFr,
          companyCoverTagLineEn: $companyCoverTagLineEn,
        ) {
          _id
          company
          companyUrl
          companyPresentation
          companyIntroFr
          companyIntroEn
          companyPhone
          companyEmail
          companyFacebookUrl
          companyLinkedinUrl
          companyCoverTagLineFr
          companyCoverTagLineEn
        }
      }
    }
  `;

export default function useMutationOrganizationUpdate() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
