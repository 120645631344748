import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import "./JobDashboardCommissionBlock.scss";
import useTranslationJobDashboard from "../useTranslationJobDashboard";
import PageSubtitle from "../../common/widgets/PageSubtitle";

function JobDashboardCommissionBlock(props) {
  const { job } = props;

  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading, "JobDashboardCommissionBlock");

  const commissionTitle = t("job-dashboard:job_dashboard_commission_label");

  return (
    <div className="JobDashboardCommissionBlock">
      <PageSubtitle colTitle={commissionTitle} />
      <Row>
        <Col>
          {/* Empty row for vertical alignment */}
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col className="commission-col">
          {job.commission > 0 && (
            <span className="commission-span">
              {t("job-dashboard:job_dashboard_job_details_commission_amounts", {
                amount: job.commission,
              })}
            </span>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default JobDashboardCommissionBlock;
