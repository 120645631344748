import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import RefreshButton from "../../common/widgets/RefreshButton";
import "./RecruiterListBlock.scss";
import RecruiterList from "./RecruiterList";

function RecruiterListBlock() {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "RecruiterListBlock");

  return (
    <div className="RecruiterListBlock">
      <Row>
        <Col>
          <h1>{t("admin:admin_recruiter_list_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <RefreshButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <RecruiterList />
        </Col>
      </Row>
    </div>
  );
}

export default RecruiterListBlock;
