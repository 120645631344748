import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobCreate";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
      $employerId: ID!,
      $title: String!
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          employerId: $employerId,
          title: $title
          lastSavedStep: TITLE
        ) {
          _id
          title
          lastSavedStep
        }
      }
    }
  `;

export default function useMutationJobCreate() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
