import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobUpdate from "./useMutationJobUpdate";
import { STEP_ENUM } from "./JobSaveForm";
import StringUtils from "../../../utils/StringUtils";
import "./JobSaveCommissionForm.scss";
import ValidatedInputText from "../../common/widgets/ValidatedInputText";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm";

const minCommission = !StringUtils.isNullOrEmpty(
  process.env.REACT_APP_COMMISSION_MIN
)
  ? process.env.REACT_APP_COMMISSION_MIN
  : 100;
const maxCommission = !StringUtils.isNullOrEmpty(
  process.env.REACT_APP_COMMISSION_MAX
)
  ? process.env.REACT_APP_COMMISSION_MAX
  : 1000;

export default function useJobSaveCommissionForm(job, isStepSaved) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { mutate, loading: mutationLoading, errors } = useMutationJobUpdate();
  useShowLoader(tLoading);

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = () => {
    return isValid;
  };

  const submit = () => {
    const commission = getValues().commission;
    return mutate({
      variables: {
        id: job._id,
        commission: parseInt(commission.toString()),
        lastSavedStep: isStepSaved ? undefined : STEP_ENUM.COMMISSION,
      },
    });
  };

  const form = (
    <div className="JobSaveCommissionForm spaced-children widget-block">
      <Row>
        <Col className={"form-col spaced-children "}>
          <Row className="reward">
            <Col>
              {t("jobs:employers_job_save_commission_step_invite_reward")}
            </Col>
          </Row>
          <Row className="note1">
            <Col>
              {t("jobs:employers_job_save_commission_step_invite_note1")}
            </Col>
          </Row>
          <Row>
            <Col className="commission-col">
              <ValidatedInputText
                name="commission"
                control={control}
                type="number"
                step="1"
                min={minCommission}
                defaultValue={job.commission}
                max={maxCommission}
                required
                placeholder="$"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  return {
    form,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: mutationLoading,
    errors,
  };
}
