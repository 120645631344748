class TypeUtils {
  /**
   * Returns an empty array if argument is not an array (or null, or undefined), otherwise returns the argument
   * @param anArray Array to return, if it is an array
   * @returns {*} The array argument, or [] if argument is not an array
   */
  static ensureArray = (anArray) => {
    return Array.isArray(anArray) ? anArray : [];
  };

  /**
   * Returns whether an array is empty.
   * @param anArray Array to check
   * @returns {*} True if array is null or is empty, false otherwise (array has at least one element)
   */
  static arrayIsEmpty = (anArray) => {
    return TypeUtils.ensureArray(anArray).length === 0;
  };

  /**
   * Returns an array whose empty elements (null, undefined, "") are removed
   * @param anArray Array to clean
   * @returns {*} The array argument with empty elements removed, or [] if argument is not an array
   */
  static arrayRemoveNullOrEmpty = (anArray) => {
    return TypeUtils.ensureArray(anArray).filter(item => item != null && item !== "");
  }

  /**
   * Returns an empty object if argument is not an object (or null, or undefined), otherwise returns the argument
   * @param anObject Object to return, if it is an object
   * @returns {*} The object argument, or {} if argument is not an object
   */
  static ensureObject = (anObject) => {
    return (anObject == null) ? {} : anObject;
  };

  /**
   * Flatten an array recursively. Emulates Array.prototype.flat which does not exist on older browsers.
   * @param array Array to flatten
   * @returns {Array} Flattened array
   */
  static flatten = array => {
    const flattened = [];
    (function flat(array) {
      array.forEach(function(el) {
        if (Array.isArray(el)) flat(el);
        else flattened.push(el);
      });
    })(array);
    return flattened;
  };

  /**
   * Make a shallow copy of an array. Useful to change an array in a React state.
   * @param array Original
   * @returns {any} Copy
   */
  static shallowCopyArray(array) {
    return array === null ? null : Array.from(array);
  }

  /**
   * Make a shallow copy of an array, find first element using callback function and, if found, replace it by new element.
   * Useful to change a React prop that is an array.
   * @param array Original
   * @param value New element
   * @param findFunction Callback to match the element to replace
   * @returns {null|any} Modified copy
   */
  static shallowCopyArrayFindAndReplace(array, value, findFunction) {
    const arrayCopy = TypeUtils.shallowCopyArray(array);
    if (arrayCopy === null)
      return null;

    const index = arrayCopy.findIndex(findFunction);
    if (index > -1)
      arrayCopy[index] = value;
    return arrayCopy;
  }

  /**
   * Make a shallow copy of an array, and removes all elements for which callback returns true.
   * Useful to change a React prop that is an array.
   * @param array Original
   * @param findFunction Callback to find elements to remove
   * @returns {null|any} Modified copy
   */
  static shallowCopyArrayFindAndRemove(array, findFunction) {
    return array === null ? null : array.filter(x => !findFunction(x));
  }

  /**
   * Make a shallow copy of an array, and push element at the end
   * Useful to change a React prop that is an array.
   * @param array Original
   * @param value Element to push
   * @returns {null|any} Modified copy
   */
  static shallowCopyArrayPush(array, value) {
    if (array === null)
      return null;

    return array.concat([value]);
  }

  /**
   * Make a shallow copy of an object with properties. Useful to change a React prop that is an object.
   * @param object Original
   * @returns {null|object} Copy
   */
  static shallowCopyObject(object) {
    return object === null ? null : TypeUtils.assign({}, object);
  }

  /**
   * Make a shallow copy of an object with properties, and set a property to a given value.
   * Useful to change a React prop that is an object.
   * @param object Original
   * @param key Property to change
   * @param value New property value
   * @returns {Object|null} Modified copy
   */
  static shallowCopyObjectSetProp(object, key, value) {
    const objectCopy = TypeUtils.shallowCopyObject(object);
    if (objectCopy === null)
      return null;

    objectCopy[key] = value;
    return objectCopy;
  }

  /**
   * Proxy for the selected polyfill/ponyfill method for Object.assign, for compatibility with IE 11.
   * Currently, Object.assign is polyfilled by importing react-app-polyfill before React in src/index.js.
   */
  static assign = Object.assign;

  /**
   * Returns the first defined argument (non null and not undefined). If all arguments are null or undefined, return null;
   * @param args
   * @return First non null and not undefined argument, or null
   */
  static firstDefined(...args) {

    for (let i = 0; i < args.length; i++)
      if (args[i] !== null && args[i] !== undefined)
        return args[i];

    return null;
  }
}

export default TypeUtils;
