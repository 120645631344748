import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useQueryJobCount from "../view/useQueryJobCount";

export default function JobByEmployerCountItem(props) {
  const { employerId } = props;
  const { data: count, loading, errors } = useQueryJobCount({employerId});

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <Row>
            <Col>
              <span>{count}</span>
            </Col>
          </Row>
        )}
      />
    </HorizontalLoader>
  );
}
