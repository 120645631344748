import React from "react";
import Page from "../../common/page/Page";
import useTranslationJobs from "../useTranslationJobs";
import useAuthUser from "../../authentication-no-ui/useAuthUser";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import JobCreateForm from "./JobCreateForm";
import JobUpdateForm from "./JobUpdateForm";
import ScrollUtils from "../../common/page/ScrollUtils";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";

export default function JobCreatePage(/*props*/) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { profile } = useAuthUser();
  const location = useLocation();
  const history = useHistory();

  // Current step
  const step = queryString.parse(location.search).step;

  // JobId, if present (if not present, it's'to create a job, otherwise it's to update the job)
  const jobId = queryString.parse(location.search).jobId;

  // Callback when one of the form components wants to change to another page. We want the URL to change so that
  // the browser's back button works, that's why we push another page to the history.
  // We pass jobId as well, because it changes when a job is created
  const changeStep = (step, jobId) => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({ step, jobId }),
    });
    ScrollUtils.scrollToTop();
  };

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="JobCreatePage">
          <Page.Title loading={tLoading}>
            {t("jobs:employers_create_job_title")}
          </Page.Title>
          <Page.Content>
            {jobId ? (
              <JobUpdateForm
                jobId={jobId}
                step={step}
                onChangeStep={changeStep}
              />
            ) : (
              <JobCreateForm
                employerId={profile._id}
                onChangeStep={changeStep}
              />
            )}
          </Page.Content>
        </Page>
      )}
    />
  );
}
