import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import AuthUserProvider from "../authentication-no-ui/AuthUserProvider";
import FirebaseProvider from "../infra-no-ui/firebase/FirebaseProvider";
import BasePage from "../common/page/BasePage";
import DefaultLanguageSwitcher from "../common/page/DefaultLanguageSwitcher";
import TranslationProvider from "../infra-no-ui/translation/TranslationProvider";
import FirebaseAuthProvider from "../authentication-no-ui/FirebaseAuthProvider";
import NotFoundPage from "./NotFoundPage";
import AuthenticatedApolloProvider from "../authentication-no-ui/AuthenticatedApolloProvider";
import MissingProfileInterceptor from "../authentication-ui/MissingProfileInterceptor";
import DisconnectedModal from "../authentication-ui/DisconnectedModal";
import ResetPasswordPage from "../authentication-ui/ResetPasswordPage";
import FirebaseErrorPage from "../authentication-ui/FirebaseErrorPage";
import DatePickerLocaleProvider from "./DatePickerLocalizationProvider";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import Loader from "../common/loading-widgets/Loader";
import Menu from "../menu/Menu";
import SignOutPage from "../authentication-ui/SignOutPage";
import SignUpPage from "../authentication-ui/SignUpPage";
import SignInPage from "../authentication-ui/SignInPage";
import JobsPage from "../jobs/view/JobsPage";
import JobCreatePage from "../jobs/mutate/JobCreatePage";
import CandidatesPage from "../candidates/view/CandidatesPage";
import ProfilePage from "../profile/view/ProfilePage.js";
import JobDashboardPage from "../job-dashboard/view/JobDashboardPage";
import { apolloCacheManager } from "./apolloCacheManager";
import useQueryEmployerByEmail from "../employers/useQueryEmployerByEmail";
import JobUpdatePage from "../jobs/mutate/JobUpdatePage";
import AdminPage from "../admin/view/AdminPage";
import PaymentPage from "../admin/view/PaymentPage";
import ApplicationCountByJobPage from "../admin/view/ApplicationCountByJobPage";
import MenuLogo from "../menu/MenuLogo";
import HeaderNavbar from "../menu/HeaderNavbar";
import HeaderPortal from "../common/page/HeaderPortal";
import TranslatedContentError from "../common/error-boundaries/TranslatedContentError";
import ErrorBoundary from "../common/error-boundaries/ErrorBoundary";
import ConnectionModePage from "../authentication-ui/ConnectionModePage";
import ConnectionModeSignUpPage from "../authentication-ui/ConnectionModeSignUpPage";
import ApolloErrorLogger from "../common/error-boundaries/ApolloErrorLogger";
import ErrorTestPage from "./ErrorTestPage";
import RecruiterListPage from "../admin/view/RecruiterListPage";
import ApplicationListPage from "../admin/view/ApplicationListPage";
import EmployerListPage from "../admin/view/EmployerListPage";
import DetailedJobListPage from "../admin/view/DetailedJobListPage";
import IntercomWidgetProvider from "../intercom/IntercomWidgetProvider";
import IntercomSetup from "../intercom/IntercomSetup";

export default function AppRouter() {

  return (
    <Router>
      <IntercomWidgetProvider>
        {/* Router must come before BasePage, because BasePage ultimately renders links */}
        {/* Display BasePage before everything else, so that we can use it to display translation and authentication errors. */}
        {/* BasePage will use portals to display menu and footer, which depend on translation and authentication that are not yet ready */}
        <BasePage header={<HeaderNavbar logo={<MenuLogo />} />}>
          <Loader>
            <TranslationProvider useShowLoader={useShowLoader}>
              <ErrorBoundary component={<TranslatedContentError />}>
                <DatePickerLocaleProvider>
                  <FirebaseProvider errorComponent={FirebaseErrorPage}>
                    <FirebaseAuthProvider disconnectedModal={DisconnectedModal}>
                      <AuthenticatedApolloProvider
                        cacheManager={apolloCacheManager()}
                      >
                        {/* At this point we can log frontend errors using Apollo because it is now available */}
                        <ErrorBoundary
                          component={<TranslatedContentError />}
                          logger={ApolloErrorLogger}
                        >
                          <AuthUserProvider
                            useQueryProfile={useQueryEmployerByEmail}
                          >
                            <DefaultLanguageSwitcher>
                              <IntercomSetup />
                              <HeaderPortal
                                component={
                                  <HeaderNavbar
                                    logo={<MenuLogo />}
                                    menu={<Menu />}
                                  />
                                }
                              />
                              <Switch>
                                {/* Put that before MissingProfileInterceptor because missing profile must not prevent from signing out */}
                                <Route
                                  exact
                                  path={ROUTES.SIGN_OUT}
                                  component={SignOutPage}
                                />
                                <Route>
                                  {/* Don't go deeper if user is authenticated but has no profile */}
                                  <MissingProfileInterceptor>
                                    <Switch>
                                      {/* Try to keep declarations here and in routes.js in the same order, for maintainability */}
                                      <Route
                                        exact
                                        path={ROUTES.CONNECTION_MODE}
                                        component={ConnectionModePage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.CONNECTION_MODE_SIGN_UP}
                                        component={ConnectionModeSignUpPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.SIGN_IN}
                                        component={SignInPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.SIGN_UP}
                                        component={SignUpPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.HOME}
                                        component={JobsPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_DASHBOARD}
                                        component={
                                          JobsPage
                                        } /* Until a more elaborate dashboard page is implemented */
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_ACTIVE_JOBS}
                                        component={JobsPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_JOB_CREATE}
                                        component={JobCreatePage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_JOB_UPDATE}
                                        component={JobUpdatePage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_JOB_DASHBOARD}
                                        component={JobDashboardPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_JOB_CANDIDATES}
                                        component={CandidatesPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_PROFILE}
                                        component={ProfilePage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_ADMIN}
                                        component={AdminPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_ADMIN_PAYMENT}
                                        component={PaymentPage}
                                      />
                                      <Route
                                        exact
                                        path={
                                          ROUTES.EMPLOYER_ADMIN_APPLICATION_COUNT_BY_JOB
                                        }
                                        component={ApplicationCountByJobPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_ADMIN_RECRUITERS}
                                        component={RecruiterListPage}
                                      />
                                      <Route
                                        exact
                                        path={
                                          ROUTES.EMPLOYER_ADMIN_APPLICATIONS
                                        }
                                        component={ApplicationListPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_ADMIN_EMPLOYERS}
                                        component={EmployerListPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.EMPLOYER_ADMIN_JOBS}
                                        component={DetailedJobListPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.RESET_PASSWORD}
                                        component={ResetPasswordPage}
                                      />
                                      <Route
                                        exact
                                        path={ROUTES.ERROR_TEST}
                                        component={ErrorTestPage}
                                      />
                                      {/* Catch-all rule when none of the above matches */}
                                      <Route
                                        path="/"
                                        component={NotFoundPage}
                                      />
                                    </Switch>
                                  </MissingProfileInterceptor>
                                </Route>
                              </Switch>
                            </DefaultLanguageSwitcher>
                          </AuthUserProvider>
                        </ErrorBoundary>
                      </AuthenticatedApolloProvider>
                    </FirebaseAuthProvider>
                  </FirebaseProvider>
                </DatePickerLocaleProvider>
              </ErrorBoundary>
            </TranslationProvider>
          </Loader>
        </BasePage>
      </IntercomWidgetProvider>
    </Router>
  );
}
