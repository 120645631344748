import React from "react";
import InlineEditValidatedInputWysiwyg from "../../common/widgets/InlineEditValidatedInputWysiwyg";
import ApplicationInlineEditInput from "./ApplicationInlineEditInput";

/**
 * Generic component for all application editable wysiwyg fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ApplicationInlineEditInputWysiwyg(props) {
  return <ApplicationInlineEditInput InlineEditInputComponent={InlineEditValidatedInputWysiwyg} {...props}/>
};
