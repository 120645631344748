import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobPublish from "./useMutationJobPublish.js";
import "./JobSavePublicationForm.scss";

export default function useJobSavePublicationForm(job, /*isStepSaved*/) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { mutate, loading: mutationLoading, errors } = useMutationJobPublish();
  useShowLoader(tLoading);

  const canSubmit = () => {
    return true;
  };

  const submit = () => {
    return mutate({
      variables: {
        id: job._id,
      },
    });
  };

  const form = (
    <div className="JobSavePublicationForm">
      <Row className="title-row">
        <Col>{t("jobs:employers_job_save_publish_step_invite")}</Col>
      </Row>
    </div>
  );

  return {
    form,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: mutationLoading,
    errors,
  };
}
