import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobUpdate from "./useMutationJobUpdate";
import { STEP_ENUM } from "./JobSaveForm";
import StringUtils from "../../../utils/StringUtils";
import { FormControl } from "react-bootstrap";
import "./JobSaveSalaryForm.scss";
import EmploymentTypeEnum, {getEmploymentTypeLabel} from "../EmploymentTypeEnum";

export default function useJobSaveSalaryForm(job, isStepSaved) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { mutate, loading: mutationLoading, errors } = useMutationJobUpdate();
  useShowLoader(tLoading);

  const [fields, setFields] = React.useState({
    employmentType: StringUtils.nullToEmpty(job.employmentType),
  });

  const onChange = (event) => {
    setFields((prevFields) => ({
      // Copy all field values from previous state
      ...prevFields,
      // Override the value that just changed
      [event.target.name]: event.target.value,
    }));
  };

  const canSubmit = () => {
    return true;
  };

  const submit = () => {
    return mutate({
      variables: {
        id: job._id,
        employmentType: StringUtils.isNullOrEmpty(fields.employmentType) ? null : fields.employmentType,
        lastSavedStep: isStepSaved ? undefined : STEP_ENUM.SALARY,
      },
    });
  };

  const form = (
    <div className="JobSaveSalaryForm spaced-children widget-block">
      <Row>
        <Col className={"label-col"}>
          <div className="label-div">
            <p className="label">
              {t("jobs:employers_job_save_salary_employment_type_label")}
            </p>
            <FormControl
              as={"select"}
              custom
              name={"employmentType"}
              onChange={onChange}
              value={fields.employmentType}
              className={"employmentType-input"}
            >
              {Object.values(EmploymentTypeEnum).map((employmentType) => (
                <option key={employmentType} value={employmentType}>
                  {getEmploymentTypeLabel(t, employmentType)}
                </option>
              ))}
            </FormControl>
          </div>
        </Col>
      </Row>
    </div>
  );

  return {
    form,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: mutationLoading,
    errors,
  };
}
