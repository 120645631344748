import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationJobs from "../useTranslationJobs";
import useMutationJobCreate from "./useMutationJobCreate";
import useMutationJobUpdateDescription from "./useMutationJobUpdateDescription";
import ValidatedInputText from "../../common/widgets/ValidatedInputText";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm";
import "./jobSaveTitleForm.scss";

export default function useJobSaveTitleForm(job) {
  const { t, loading: tLoading } = useTranslationJobs();
  const {
    mutate: creationMutate,
    loading: creationLoading,
    errors: creationErrors,
  } = useMutationJobCreate();
  const {
    mutate: updateMutate,
    loading: updateLoading,
    errors: updateErrors,
  } = useMutationJobUpdateDescription();
  useShowLoader(tLoading);

  const errorMsgs = creationErrors || updateErrors;

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = () => {
    return isValid;
  };

  const submit = () => {
    const values = getValues();

    if (job._id) {
      return updateMutate({
        variables: {
          id: job._id,
          title: values.title,
        },
      });
    } else {
      return creationMutate({
        variables: {
          employerId: job.employerId,
          title: values.title,
        },
      });
    }
  };

  const form = (
    <div className="JobSaveTitleForm ">
      <Row>
        <Col>
          <div className="job-title-container">
            <Row className="job-title">
              <Col>{t("jobs:employers_job_save_title_invite_msg")}</Col>
            </Row>
            <Row>
              <Col>
                <ValidatedInputText
                  type="text"
                  required={true}
                  name="title"
                  control={control}
                  defaultValue={job.title}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );

  return {
    form,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: creationLoading || updateLoading,
    errors: errorMsgs,
  };
}
