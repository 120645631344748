import React from "react";
import Page from "../../common/page/Page";
import useTranslationAdmin from "../useTranslationAdmin";
import ApplicationCountByJobBlock from "./ApplicationCountByJobBlock";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";


export default function ApplicationCountByJobPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "ApplicationCountByJobPage");

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="ApplicationCountByJobPage">
          <Page.Title
            text={t("admin:admin_application_count_by_job_page_title")}
            loading={tLoading}
          />
          <Page.Content>
            <ApplicationCountByJobBlock />
          </Page.Content>
        </Page>
      )}
    />
  );
}
