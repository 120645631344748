import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import draftToHtml from "draftjs-to-html";
import useLanguages from "../../infra-no-ui/translation/useLanguages";
import { filterEditorState } from "draftjs-filters";

/**
 * Helper function to return the text displayed in the Wysiwyg. Hidden html tags and bullets are ignored.
 * @param editorState Editor content (as an EditorState object)
 * @returns {string} visible text
 */
export const getWysiwygContent = (editorState) => {
  return editorState ? editorState.getCurrentContent().getPlainText() : "";
};

/**
 * Helper function to return the number of characters displayed in the Wysiwyg. Hidden html tags and bullets are ignored.
 * @param editorState Editor content (as an EditorState object)
 * @returns {number|number} Number of characters
 */
export const getWysiwygContentLength = (editorState) => {
  return getWysiwygContent(editorState).length;
};

/**
 * Filters wysiwyg content to preserve only the formatting you allow.
 * @param editorState Editor state of the wysiwyg
 * @returns {*} Cleaned editorState
*/
export function cleanEditorState(editorState) {
  return filterEditorState(
    {
      blocks: ["unordered-list-item", "ordered-list-item"],
      styles: ["BOLD"],
      entities: [{
        type: "LINK"
      }],
      maxNesting: 1,
      whitespacedCharacters: ["\n"],
    },
    editorState
  );
}

/**
 * Helper function to convert an HTML string to a state usable by the wysiwyg editor
 * @param input HTML string
 * @returns {EditorState} EditorState object
 */
export const formatWysiwygInput = (input) => {
  return EditorState.createWithContent(stateFromHTML(input));
};

/**
 * Helper function to convert the editor content (encoded as an editor state) to an HTML string
 * @param output EditorState object
 * @returns {*|string} HTML string
 */
export const formatWysiwygOutput = (output) => {
  return output ? draftToHtml(convertToRaw(cleanEditorState(output).getCurrentContent())) : "";
};

export default function WysiwygEditor(props) {
  const { editorRef, value, onChange, onBlur, placeholder } = props;

  const { getCurrentLanguageCode } = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();

  // The Editor is enclosed in a div so that we can set styles on ".WysiwygEditor .WysiwygEditor-editor"
  // (or another subclass), which will override the more generic styles on .rdw-editor default classes.
  return (
    <div className={"WysiwygEditor"}>
      <Editor
        wrapperClassName="WysiwygEditor-wrapper"
        editorClassName="WysiwygEditor-editor"
        toolbarClassName="WysiwygEditor-toolbar"
        editorRef={
          editorRef
        } /* Get a ref to the editor so that we can focus on it */
        editorState={cleanEditorState(value)}
        onEditorStateChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        toolbar={{
          options: ["inline", "link", "list", "history"],
          inline: {
            options: ["bold"],
          },
          link: {
            defaultTargetOption: '_blank', // Open links in new window, by default
          },
        }}
        localization={{
          locale: currentLanguageCode,
        }}
      />
    </div>
  );
}
