import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import JobList from "./JobList";
import RefreshButton from "../../common/widgets/RefreshButton";
import "./PaymentBlock.scss";
import JobStatusEnum from "../../jobs/JobStatusEnum";
import JobPaymentStatusEnum from "../../jobs/JobPaymentStatusEnum";

function PaymentBlock() {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "PaymentBlock");

  return (
    <div className="PaymentBlock">
      <Row>
        <Col>
          <h1>{t("admin:admin_payment_page_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <RefreshButton />
        </Col>
      </Row>
      <Row>
        <Tab.Container id="status-tab" defaultActiveKey="unpublished">
          <Row className="widget-block">
            <Col className="nav-col">
              <Nav variant="pills" className="nav-items">
                <Nav.Item>
                  <Nav.Link eventKey="unpublished">
                    {t("admin:admin_payment_page_unpublished_label")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="published">
                    {t("admin:admin_payment_page_published_label")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="unpaid">
                    {t("admin:admin_payment_page_unpaid_label")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="paid">
                    {t("admin:admin_payment_page_paid_label")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col className="content-col">
              <Tab.Content>
                <Tab.Pane eventKey="unpublished">
                  <Row className="list-row">
                    <Col>
                      <JobList status={JobStatusEnum.PENDING} />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="published">
                  <Row className="list-row">
                    <Col>
                      <JobList status={JobStatusEnum.PUBLISHED} />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="unpaid">
                  <Row className="list-row">
                    <Col>
                      <JobList status={JobStatusEnum.PUBLISHED} paymentStatus={JobPaymentStatusEnum.PENDING} />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="paid">
                  <Row className="list-row">
                    <Col>
                      <JobList status={JobStatusEnum.PUBLISHED} paymentStatus={JobPaymentStatusEnum.PAID} />
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Row>
    </div>
  );
}

export default PaymentBlock;
