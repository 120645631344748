import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import Table from "react-bootstrap/Table";
import WaitForData from "../../common/data-fetching/WaitForData";
import TypeUtils from "../../../utils/TypeUtils";
import TrueIcon from "@material-ui/icons/Check";
import TruncateText from "./TruncateText";
import useQueryApplicationAdminSearch from "../search/useQueryApplicationAdminSearch";
import DisplayDayIso from "./DisplayDayIso";

export default function ApplicationList({filter}) {
  const {
    data: applications,
    loading,
    errors,
  } = useQueryApplicationAdminSearch(
    1000,
    filter,
    "DATE_DESC"
  );

  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading || loading, "ApplicationList");

  const columnsLabels = [
    t("admin:admin_application_date_column_label"),
    t("admin:admin_application_candidate_column_label"),
    t("admin:admin_application_company_column_label"),
    t("admin:admin_application_job_column_label"),
    t("admin:admin_application_recruiter_column_label"),
    t("admin:admin_application_recruiterNote_column_label"),
    t("admin:admin_application_status_column_label"),
    t("admin:admin_application_viewed_column_label"),
    t("admin:admin_application_candidateApprovalStatus_column_label"),
  ];

  return (
    <>
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <Row className="widget-block">
            <Col>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    {columnsLabels.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {applications["nodes"].map((item, i) => (
                    <tr key={i}>
                      <th>{i + 1}</th>
                      <td><DisplayDayIso date={item.updatedAt} /></td>
                      <td>{item.candidate && <TruncateText title={t("admin:admin_application_candidate_column_label")} text={item.candidate.name} length={30}/>}</td>
                      <td><TruncateText title={t("admin:admin_application_company_column_label")} text={item.job && item.job.employer && item.job.employer.company} length={10}/></td>
                      <td><TruncateText title={t("admin:admin_application_job_column_label")} text={item.job && item.job.title} length={15}/></td>
                      <td>{item.recruiter ? TypeUtils.arrayRemoveNullOrEmpty([item.recruiter.firstName, item.recruiter.lastName]).join(" ") : ""}</td>
                      <td><TruncateText title={t("admin:admin_application_recruiterNote_column_label")} text={item.recruiterNote} length={15}/></td>
                      <td><TruncateText title={t("admin:admin_application_status_column_label")} text={item.status} length={6}/></td>
                      <td>{item.viewed ? <TrueIcon/> : ""}</td>
                      <td><TruncateText title={t("admin:admin_application_candidateApprovalStatus_column_label")} text={item.candidateApprovalStatus} length={10}/></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      />
    </>
  );
}
