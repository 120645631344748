import React from "react";
import Page from "../../common/page/Page";
import CandidatesBlock from "./CandidatesBlock";
import useQueryJobById from "../../jobs/view/useQueryJobById";
import { useParams } from "react-router";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import WaitForData from "../../common/data-fetching/WaitForData";


export default function CandidatesPage(props) {

  // Fetch job from ID provided in the url params.
  const urlParams = useParams();
  const jobId = urlParams.jobId;
  const statusFilter = props.location.state.status;
  const {
    data: job,
    loading: jobLoading,
    errors: jobErrors,
  } = useQueryJobById(jobId);

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <WaitForData
          loading={jobLoading}
          errors={jobErrors}
          onLoaded={() => (
            <Page className="CandidatesPage">
              <Page.Title
                text={job.title}
                loading={jobLoading}
              />
              <Page.Content>
                <CandidatesBlock statusFilter={statusFilter} job={job} />
              </Page.Content>
            </Page>
          )}
        />
      )}
    />
  );
}
