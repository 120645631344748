import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useQueryHiresByJobId from "./useQueryHiresByJobId";
import WaitForData from "../../common/data-fetching/WaitForData";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized";
import ApplicationItem from "./ApplicationItem";
import useTranslationJobDashboard from "../useTranslationJobDashboard";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import PageSubtitle from "../../common/widgets/PageSubtitle";
import "./HiredCandidatesBlock.scss";

export default function HiredCandidatesBlock(props) {
  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading, "HiredCandidatesBlock");

  const { jobId } = props;
  const {
    data: hires,
    loading: hiresLoading,
    errors: hireErrors,
  } = useQueryHiresByJobId(jobId);

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <WaitForData
          loading={hiresLoading}
          errors={hireErrors}
          onLoaded={() => (
            <>
              {hires.length > 0 && (
                <div className="HiredCandidatesBlock">
                  <Row>
                    <Col className={"title-col"}>
                      <PageSubtitle
                        colTitle={t(
                          "job-dashboard:job_dashboard_hired_candidates_title"
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {hires.map((item) => (
                        <div key={item.applicationId}>
                          <ApplicationItem applicationId={item.applicationId} />
                          <hr />
                        </div>
                      ))}
                    </Col>
                  </Row>
                </div>
              )}
            </>
          )}
        />
      )}
    />
  );
}
