import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import JobDashboardAlertsBlock from "./JobDashboardAlertsBlock";
import JobDashboardApplicationsBlock from "./JobDashboardApplicationsBlock";
import JobDashboardSummaryBlock from "./JobDashboardSummaryBlock";
import JobDashboardCommissionBlock from "./JobDashboardCommissionBlock";
import JobStatistics from "./JobStatistics";
import "./JobDashboardBlock.scss";

function JobDashboardBlock(props) {
  const { job } = props;

  return (
    <div className="JobDashboardBlock">
      <Row>
        <Col>
          <JobDashboardSummaryBlock job={job} />
        </Col>
      </Row>
      <Row className={"blockContainer"}>
        <Col className="blockContainerApplication">
          <div className="widget-block">
            <JobDashboardApplicationsBlock job={job} />
          </div>
        </Col>
        <Col className="blockContainerCommission ">
          <div className="widget-block">
            <JobDashboardCommissionBlock job={job} />
          </div>
        </Col>
        <Col className="blockContainerStatistics ">
          <div className="widget-block">
            <JobStatistics job={job} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <JobDashboardAlertsBlock job={job} />
        </Col>
      </Row>
    </div>
  );
}

export default JobDashboardBlock;
