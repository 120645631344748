import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobUpdateArchivedWithHire";

export const QUERY = gql`
  mutation ${QUERY_SCOPE}_${QUERY_NAME} (
    $id: ID!,
    $applicationsId: [ID!]
  ) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME} (
        id: $id,
        applicationsId: $applicationsId,
      ) {
        _id
        status
      }
    }
  }
`;

export default function useMutationJobCloseWithHire() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
