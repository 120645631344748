import React from "react";
import StringUtils from "../../../utils/StringUtils";
import useTranslationJobs from "../useTranslationJobs";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import "./ApplicationByJobCount.scss";
import useQueryApplicationSearchCount from "./useQueryApplicationSearchCount";
import ApplicationStatusEnum from "../../candidates/ApplicationStatusEnum";

function ApplicationByJobCount(props) {
  const { jobId } = props;
  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "ApplicationByJobCount");

  // Do not include rejected applications
  const filters = {
    status: [ApplicationStatusEnum.STACK, ApplicationStatusEnum.INTERVIEW, ApplicationStatusEnum.SHORTLIST, ApplicationStatusEnum.HIRED]
  }

  const {
    data: count,
    loading,
    errors,
  } = useQueryApplicationSearchCount(jobId, filters);

  const { data: newCount, errors: viewedCountErrors } = useQueryApplicationSearchCount(jobId, {
    viewed: false,
  });


  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors || viewedCountErrors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className={"ApplicationByJobCount"}>
            {/*t can use the _plural key because we provide a "count" and a number*/}
            <span>
              {t("jobs:employers_job_details_candidate_count", {
                count: StringUtils.nullToZero(count),
              })}
            </span>
            {newCount > 0 ? (
              <span className={"notification-on"} />
            ) : (
              <span className={"notification-off"} />
            )}
          </div>
        )}
      />
    </HorizontalLoader>
  );
}

export default ApplicationByJobCount;
