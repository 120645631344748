import React from "react";
import FirebaseContext from "../infra-no-ui/firebase/FirebaseContext";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import FirebaseAuthContext from "./FirebaseAuthContext";

/**
 * Context provider that can be consumed by useFirebaseAuth to get current Firebase authentication object.
 *
 * Most of the time, use withAuthUser instead, which returns a more complete object representing the current user
 * (such as the user profile as well as the Firebase object for the user). The Firebase user contains its email,
 * its token ID (to prove that the user is authenticated) and other data managed by Firebase.
 *
 * The Firebase authenticated object provider (FirebaseAuthProvider) and complete user provider (AuthUserProvider) are separate
 * because the FirebaseAuthProvider component encloses the AuthenticatedApolloProvider (which needs to send the user
 * token ID to GraphQL), which encloses the AuthUserProvider (which needs an Apollo provider to fetch the user profile).
 * When the Firebase user changes, this provider re-renders and updates the Apollo provider with a new token and then
 * the AuthUser provider re-fetch the new user profile.
 *
 * Current Firebase authentication object is an object with the following properties:
 *
 * <ul>
 *   <li>user: the Firebase user object</li>
 *   <li>idTokenResult: ID token info
*    <li>authenticated: true if Firebase user is authenticated
 * </ul>
 *
 * This component listens to Firebase events and sets/clears the user in the context following these events.
 *
 * @param props
 * @return {null|*}
 * @constructor
 */
export default function FirebaseAuthProvider(props) {

  /**
   * Get the Firebase user token ID and set the current state with it.
   * If forceRefresh is false, the Firebase server won't be called unless the current
   * token has expired (Firebase tokens expire after one hour). If forceRefresh is true, the Firebase server will
   * be called.
   *
   * When the server is called and we learn that the user's credentials have been revoked, display a modal to explain
   * the situation. Also, the onAuthStateChanged event will be fired automatically and the page will re-render as
   * a non-authenticated user.
   *
   * @param firebaseUser Firebase user
   * @param forceRefresh True to force a Firebase server request even if local token is not expired
   */
  const setFirebaseUserIdToken = (firebaseUser, forceRefresh = false) => {

    // Check only if user is authenticated
    if (!!firebaseUser) {

      return firebaseUser.getIdTokenResult(forceRefresh)
        .then((idTokenResult) => {
          // Token is valid, return it
          setIdTokenResult(idTokenResult);
        })
        .catch((err) => {
          // Token is invalid. User must be logged out. Don't wait for onAuthStateChanged user to fire because
          // for a short period of time, user would still be recorded as authenticated but without a token
          setFirebaseUser(null);
          setIdTokenResult(null);
          setShowDisconnected(true);

          return Promise.reject(err);
        });
    } else {
      // User is not logged in. Set an empty token.
      setIdTokenResult(null);
    }
  };

  const hideDisconnectedModal = () => {
    setShowDisconnected(false);
  };

  const buildFirebaseAuthContextValue = (firebaseUser, idTokenResult) => {
    return ({
      authenticated: !!firebaseUser,
      user: firebaseUser,
      idTokenResult: idTokenResult
    });
  }

  const renderIdRef = React.useRef(0);
  renderIdRef.current = renderIdRef.current + 1;

  // In this component, when firebaseUser = undefined, it means the response from the first authentication attempt
  // has not been received yet (the onAuthStateChanged event has not been fired yet or has not returned yet).
  // When firebaseUser = null, it means either the user is not authenticated, either an error occurred.
  //
  // The firebaseUser variable is then put in firebaseAuthContextValue.user, which is the context
  // passed to children that need to know the authentication status. Beware that firebaseAuthContextValue.user may
  // be updated after firebaseUser, so all checks should be performed on firebaseAuthContextValue.user instead of firebaesUser.
  //
  // Until we get a response from onAuthStateChanged (either an object or null), display a loader and render null instead
  // of rendering the children.
  // This is to avoid being redirected to Sign Up or Sign In when refreshing a private page: on the first render,
  // before the onAuthStateChanged effect is registered, firebase user won't be defined when the private page child is
  // displayed, and the latter will redirect the user to a public page. An effect will display the loader.

  const [firebaseUser, setFirebaseUser] = React.useState(undefined);
  const [showDisconnected, setShowDisconnected] = React.useState(false);
  const [idTokenResult, setIdTokenResult] = React.useState(null);
  const [firebaseAuthContextValue, setFirebaseAuthContextValue] = React.useState(buildFirebaseAuthContextValue(undefined, null));
  const firebase = React.useContext(FirebaseContext);
  const loading = firebaseAuthContextValue.user === undefined;
  useShowLoader(loading, "FirebaseAuthProvider");

  // Listen for changes in the status of the authenticated user.
  React.useEffect(() => {
    // onAuthStateChanged returns a function to call to stop listening
    const unlisten = firebase.auth.onAuthStateChanged(firebaseUser => {
      // Update user; it is either an object or null
      setFirebaseUser(firebaseUser);
    });

    // When unmounting, stop listening to state changes
    return () => {
      unlisten();
    }
  }, [firebase.auth]);

  // Every time firebaseUser changes, get its ID token.
  // If a refresh delay has been set in the environment variables, get a new ID token periodically, which will enable
  // us to know if user is still authenticated. A refresh delay is useful when we want to check at intervals smaller than
  // one hour, because Firebase ID tokens a time-to-live of one hour. It is not useful to specify a refresh delay greater
  // than one hour.
  // Register a timer with setInterval to contact the Firebase server periodically. A new setInterval must be made
  // everytime the firebaseUser changes because getFirebaseUserIdToken depends on it
  // (it doesn't take the current value, it takes the value at the interval creation)
  React.useEffect(() => {
    const refreshDelay = process.env.REACT_APP_AUTH_REFRESH_DELAY;

    // Set ID token right now
    setFirebaseUserIdToken(firebaseUser, false);

    // Set a timer to check periodically in the future
    if (refreshDelay > 0) {
      const interval = setInterval(() => setFirebaseUserIdToken(firebaseUser, true), refreshDelay);

      // Return a function to stop the timer
      return () => {
        clearInterval(interval)
      };
    } else {

    }
  }, [firebaseUser]);

  // When firebaseUser is authenticated but has no token, do not update context; this avoid a refresh after user
  // has authenticated but token has not been received yet. If there is no token because an error occurred while
  // getting it, the function that gets the token should sign out the user
  React.useEffect(() => {
    if (!firebaseUser || idTokenResult) {
      setFirebaseAuthContextValue(buildFirebaseAuthContextValue(firebaseUser, idTokenResult));
    }
  }, [firebaseUser, idTokenResult]);

  // RENDER

  // While waiting for authentication status, do not render children; a private page might trigger a redirect because
  // we are not authenticated yet. See note above about firebaseUser.
  if (loading) {
    return null;
  }

  // Modal to show when disconnected is passed as a prop because it contains presentation elements that may vary from site to site
  const DisconnectedModal = props.disconnectedModal;

  // Display children. If the re-render is caused by the disconnection of the user, display an information modal too.
  // The user will know the exact cause of disconnection when logging in again
  return (
    <FirebaseAuthContext.Provider value={firebaseAuthContextValue}>
      <DisconnectedModal show={showDisconnected} handleClose={hideDisconnectedModal} />
      {props.children}
    </FirebaseAuthContext.Provider>
  );
}
