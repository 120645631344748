import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import HtmlUtils from "../../../utils/HtmlUtils";
import StringUtils from "../../../utils/StringUtils";
import "./ApplicationUpdateForm.scss";
import ActionButton from "../../common/widgets/ActionButton";
import ApplicationInlineEditInputWysiwyg from "./ApplicationInlineEditInputWysiwyg";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CallIcon from "@material-ui/icons/Call";
import useTranslationCandidates from "../useTranslationCandidates";
import Separator from "../../common/widgets/Separator";
import Dropdown from "react-bootstrap/Dropdown";
import useMutationCandidateUpdate from "../mutate/useMutationApplicationUpdate";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import useErrorModal from "../../common/modals/useErrorModal";
import ActionLink from "../../common/widgets/ActionLink";
import ResumeDownloadIcon from "../view/ResumeDownloadIcon";
import useQueryApplicationById from "../view/useQueryApplicationById";
import WaitForData from "../../common/data-fetching/WaitForData";
import { MAX_LENGTH_EMPLOYER_NOTE } from "../../../constants/ui";
import RouterUtils from "../../../utils/RouterUtils";

export default function ApplicationUpdateForm(props) {
  const { applicationId, onClose, onFilterChange } = props;

  const {
    data: application,
    loading: applicationLoading,
    errors: applicationErrors,
  } = useQueryApplicationById(applicationId);

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ApplicationUpdateForm");

  const { mutate, errors } = useMutationCandidateUpdate();
  const errorMsg = useServerErrorFormatter(errors);
  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("candidates:candidates_details_update_error")}</p>
      {errorMsg}
    </>
  );

  const onClickLink = (url) => {
    RouterUtils.openExternalUrl(url);
  };

  // Parse recruiter note only when application object changes
  const recruiterNote = React.useMemo(() => {
    return application
      ? HtmlUtils.htmlToComponents(application.recruiterNote)
      : "";
  }, [application]);

  const filterLabel = {
    STACK: t("candidates:candidates_send_to_stack_label"),
    REJECTED: t("candidates:candidates_send_to_rejected_label"),
    SHORTLIST: t("candidates:candidates_send_to_shortlist_label"),
    INTERVIEW: t("candidates:candidates_send_to_interview_label"),
  };

  const handleChangeStatus = (status) => {
    const variables = {};
    variables["id"] = application._id;
    variables["status"] = status;
    mutate({ variables }).catch(() => {
      showErrorModal();
    });
    onFilterChange(status);
  };

  return (
    <>
      {ErrorModal}
      <WaitForData
        loading={applicationLoading}
        errors={applicationErrors}
        onLoaded={() => (
          <div className={"ApplicationUpdateForm"}>
            <Row className={"first-row"}>
              <Col className="candidateInformationContact">
                <Row className={"name-row"}>
                  <Col className="name-col">
                    <h1>{application.candidate.name}</h1>
                  </Col>
                  {onFilterChange && (
                    <Col className="icon-col">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-status">
                          {filterLabel[application.status]}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {Object.keys(filterLabel)
                            .filter((item) => item !== application.status)
                            .map((item) => (
                              <Dropdown.Item
                                key={item}
                                onClick={() => handleChangeStatus(item)}
                              >
                                {filterLabel[item]}
                              </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  )}
                </Row>

                {application.resumeId && (
                  <Row className={"resume-row"}>
                    <Col>
                      <ResumeDownloadIcon
                        resumeId={application.resumeId}
                        showLabel={true}
                      />
                    </Col>
                  </Row>
                )}
                <Row className={"label-row"}>
                  <Col>
                    {t("candidates:candidates_contact_information_label")}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row className={"contact-row"}>
                      <Col>
                        <EmailIcon /> {application.candidate.email}
                      </Col>
                    </Row>
                    {!StringUtils.isNullOrEmpty(
                      application.candidate.phone
                    ) && (
                      <Row className={"contact-row"}>
                        <Col className="icon-col">
                          <CallIcon /> {application.candidate.phone}
                        </Col>
                      </Row>
                    )}
                    {!StringUtils.isNullOrEmpty(
                      application.candidate.linkedin
                    ) && (
                      <Row className={"contact-row"}>
                        <Col className="icon-col">
                          <LinkedInIcon />
                          <ActionLink
                            onClick={() =>
                              onClickLink(application.candidate.linkedin)
                            }
                          >
                            {t("candidates:candidates_linkedin_label")}
                          </ActionLink>
                        </Col>
                      </Row>
                    )}
                    {!StringUtils.isNullOrEmpty(
                      application.candidate.indeed
                    ) && (
                      <Row className={"contact-row"}>
                        <Col className="icon-col">
                          <AccountBoxIcon />
                          <ActionLink
                            onClick={() =>
                              onClickLink(application.candidate.indeed)
                            }
                          >
                            {t("candidates:candidates_indeed_label")}
                          </ActionLink>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Separator />
            {application.recruiter.isActiveAccount && (
              <>
                <Row className={"label-row"}>
                  <Col>{t("candidates:candidates_recruiter_name_label")}</Col>
                </Row>
                <Row>
                  <Col>
                    {application.recruiter.firstName}{" "}
                    {application.recruiter.lastName}
                  </Col>
                </Row>
                <Row className={"contact-row"}>
                  <Col className="icon-col">
                    <EmailIcon />
                  </Col>
                  <Col className={"contact-col"}>{application.recruiter.email}</Col>
                </Row>
                {!StringUtils.isNullOrEmpty(application.recruiter.phone) && (
                  <Row className={"contact-row"}>
                    <Col className="icon-col">
                      <CallIcon />
                    </Col>
                    <Col className={"contact-col"}>{application.recruiter.phone}</Col>
                  </Row>
                )}
              </>
            )}
            {!application.recruiter.isActiveAccount && (
              <Row>
                <Col className={"label-row"}>
                  {t("candidates:candidates_recruiter_deleted_account_label")}
                </Col>
              </Row>
            )}
            {!StringUtils.isNullOrEmpty(recruiterNote) && (
              <>
                <Row className={"label-row"}>
                  <Col>{t("candidates:candidates_recruiter_note_label")}</Col>
                </Row>
                <Row>
                  <Col>{recruiterNote}</Col>
                </Row>
              </>
            )}
            <Separator />
            <Row className={"label-row"}>
              <Col> {t("candidates:candidates_note_label")}</Col>
            </Row>
            <Row>
              <Col className="note-col">
                <ApplicationInlineEditInputWysiwyg
                  className={"note-input"}
                  application={application}
                  placeholder={t(
                    "candidates:candidates_candidate_note_placeholder"
                  )}
                  property={"employerNote"}
                  maxLength={MAX_LENGTH_EMPLOYER_NOTE}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-actionButton">
                <ActionButton onClick={onClose}>
                  {t("candidates:candidates_close_action")}
                </ActionButton>
              </Col>
            </Row>
          </div>
        )}
      />
    </>
  );
}
