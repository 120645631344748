import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobCountsByStatus";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($filter: JobFilterInput) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(filter: $filter) {
        _id
        status
        count
      }
    }
  }
`;

export default function useQueryJobCountsByStatus(filter) {
  const variables = { filter};

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME,{
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
