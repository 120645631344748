import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobUpdateArchivedWithoutHire";

export const QUERY = gql`
  mutation ${QUERY_SCOPE}_${QUERY_NAME} (
    $id: ID!,
    $isCancelled: Boolean,
    $isExternalHire: Boolean
  ) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME} (
        id: $id,
        isCancelled: $isCancelled,
        isExternalHire: $isExternalHire,
      ) {
        _id
        status
      }
    }
  }
`;

export default function useMutationJobCloseWithoutHire() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
