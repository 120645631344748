import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DateUtils from "../../../utils/DateUtils";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";

// Dates on X axis and dates from counts must be formatted the same way
const formatDateForChart = DateUtils.timelessDateToShortString;

function JobDashboardApplicationsLineChart(props) {
  const { data, createdAt, validThrough, status } = props;

  // Convert array of date counts to map of count by date (formatted) for easy retrieval of counts
  const items = Object.fromEntries(
    data.map((item) => [formatDateForChart(new Date(item.date)), item.count])
  );

  const endDate = status === "ARCHIVED" ? new Date(validThrough) : new Date();
  var daylist = DateUtils.getDaysArray(createdAt, endDate);

  const counts = daylist.map((item) => {
    const container = {};
    container["date"] = item;
    container["count"] = item in items ? items[item] : 0;
    return container;
  });

  return (
    <div className="JobDashboardApplicationsLineChart">
      <Row>
        <Col>
          <ResponsiveContainer width={"99%"} height={200}>
            <LineChart width={400} height={200} data={counts}>
              <XAxis dataKey="date" />
              <YAxis allowDecimals={false} />
              <Line
                type="monotone"
                dataKey="count"
                stroke="#68a62b"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </div>
  );
}

export default JobDashboardApplicationsLineChart;
