import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslationAdmin from "../useTranslationAdmin";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import RefreshButton from "../../common/widgets/RefreshButton";
import "./EmployerListBlock.scss";
import EmployerList from "./EmployerList";

function EmployerListBlock() {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "EmployerListBlock");

  return (
    <div className="EmployerListBlock">
      <Row>
        <Col>
          <h1>{t("admin:admin_employer_list_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <RefreshButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <EmployerList />
        </Col>
      </Row>
    </div>
  );
}

export default EmployerListBlock;
